import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {selectProfile} from '@caroo/profile/reducers';
import {ProfileState} from '@caroo/profile/reducers/profile.reducer';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';

@Component({
	selector: 'caroo-subscription-started-dialog',
	templateUrl: './subscription-started-dialog.component.html',
	styleUrls: ['./subscription-started-dialog.component.scss']
})
export class SubscriptionStartedDialogComponent {
	readonly profileCreated$ = this.profileStore.select(selectProfile).pipe(map(profile =>
		!!profile.name &&
		profile.locations.length > 0 &&
		!!profile.logo
	));

	constructor(
		private readonly matDialogRef: MatDialogRef<SubscriptionStartedDialogComponent>,
		private profileStore: Store<ProfileState>
	) {
	}

	close() {
		this.matDialogRef.close();
	}
}
