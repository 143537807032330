import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {from, Observable, of} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LocalStorage} from '../../storage/decorator';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	@LocalStorage token: string;

	constructor(
		private readonly angularFireAuth: AngularFireAuth,
		private readonly httpClient: HttpClient
	) {
	}

	private exchangeLegacyToken(legacyToken: string): Observable<string> {
		return this.httpClient
			.get<{ token: string }>(`${environment.baseUrl}/auth/firebase-auth-custom-token`, {
				headers: {
					Authorization: `Bearer ${legacyToken}`
				}
			})
			.pipe(
				switchMap(({token}) => from(this.angularFireAuth.auth.signInWithCustomToken(token))),
				switchMap(credential => from(credential.user.getIdToken()))
			);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (
			!req.url.includes(environment.baseUrl) ||
			req.url.includes('shortlists') ||
			req.url.includes('companieshouse') ||
			req.url.includes('/brands/users/accept') ||
			req.url.includes('redirect-auth-action')
		) {
			return next.handle(req);
		}
		let observable: Observable<string>;
		if (req.url.includes('firebase-auth-custom-token')) {
			observable = of(this.token);
		} else {
			observable = this.angularFireAuth.user.pipe(
				first(),
				switchMap(user => {
					if (!!user) {
						return from(user.getIdToken());
					} else {
						return this.exchangeLegacyToken(this.token);
					}
				})
			);
		}
		return observable.pipe(switchMap(idToken => {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${idToken}`
				}
			});
			return next.handle(req);
		}));
	}
}
