import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SubscriptionStartedDialogComponent} from '@caroo/payment/subscription-started-dialog/subscription-started-dialog.component';
import {ButtonModule} from '@caroo/shared/button/button.module';

@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		RouterModule
	],
	declarations: [SubscriptionStartedDialogComponent],
	entryComponents: [SubscriptionStartedDialogComponent],
	exports: [SubscriptionStartedDialogComponent]
})
export class SubscriptionStartedDialogModule {
}
