var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, throttleTime } from 'rxjs/operators';
import { ProfileActionTypes, SetProfile } from '../actions/profile.actions';
import { ProfileService } from '../services/profile.service';
export class ProfileEffects {
    constructor(actions$, profileService) {
        this.actions$ = actions$;
        this.profileService = profileService;
        this.loadProfile$ = this.actions$
            .pipe(ofType(ProfileActionTypes.LoadProfile))
            .pipe(throttleTime(1000))
            .pipe(switchMap(() => this.profileService.getProfile()))
            .pipe(map(profile => new SetProfile({ profile })));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ProfileEffects.prototype, "loadProfile$", void 0);
