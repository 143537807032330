import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {SafeModule} from '@caroo/shared/pipes/safe';
import {ResourceComponent} from '@caroo/shared/resource/resource.component';

@NgModule({
	declarations: [ResourceComponent],
	imports: [
		CommonModule,
		MatIconModule,
		SafeModule
	],
	exports: [ResourceComponent]
})
export class ResourceModule {
}
