<ng-container>
	<mat-toolbar *ngLet="numUnreadNotifications$ | async as numUnreadNotifications">
		<h2 class="subtitle">Notifications<span *ngIf="numUnreadNotifications > 0"> {{numUnreadNotifications}} unread</span></h2>
		<label class="body2" (click)="markAllNotificationsAsRead()">Mark all as read</label>
	</mat-toolbar>
	<ng-container *ngIf="notifications$ | async as notifications">
		<ng-container *ngIf="notifications.length > 0; else noNotifications">
			<caroo-notification *ngFor="let notification of notifications" [notification]="notification" (click)="onNotificationClicked(notification)"></caroo-notification>
		</ng-container>
		<ng-template #noNotifications>
			<h3 class="title no-notifications">You don't have any notifications</h3>
		</ng-template>
	</ng-container>
</ng-container>
