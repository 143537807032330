import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ButtonModule } from '@caroo/shared/button/button.module';
import { CarooFormsModule } from '@caroo/shared/forms';
import { InviteColleaguesDialogComponent } from '@caroo/shared/invite-colleagues-dialog/invite-colleagues-dialog.component';

@NgModule({
	declarations: [InviteColleaguesDialogComponent],
	imports: [
		ButtonModule,
		CarooFormsModule,
		CommonModule,
		MatDialogModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		ReactiveFormsModule,
	],
	exports: [InviteColleaguesDialogComponent],
	entryComponents: [InviteColleaguesDialogComponent]
})
export class InviteColleaguesDialogModule {
}
