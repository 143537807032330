import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Colleague, Resource} from '@caroo/shared/models';
import {UserProfile} from '@caroo/users/models/user-profile';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpClient) {
	}

	getUserProfile(): Observable<UserProfile> {
		return this.http.get<UserProfile>(`${environment.baseUrl}/brands/users/profile`);
	}

	updateUserProfile(name: string, jobTitle?: string): Observable<any> {
		return this.http.patch(`${environment.baseUrl}/brands/users/profile`, {name, jobTitle});
	}

	updateUserProfileAvatar(resource: Resource): Observable<any> {
		if (resource && resource.file) {
			const data = new FormData();
			data.append('avatar', resource.file);
			return this.http.put<any>(`${environment.baseUrl}/brands/users/profile/avatar`, data);
		} else {
			return of({});
		}
	}

	getColleagues(): Observable<Colleague[]> {
		return this.http.get<Colleague[]>(`${environment.baseUrl}/brands/users/colleagues`);
	}

	inviteColleagues(emails: string[]): Observable<any> {
		return this.http.post<any>(`${environment.baseUrl}/brands/users`, {emails});
	}
}
