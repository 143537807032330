var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setContext } from 'apollo-link-context';
import { first } from 'rxjs/operators';
export const authLink = (angularFireAuth) => setContext((_, { headers }) => __awaiter(this, void 0, void 0, function* () {
    const currentUser = yield angularFireAuth.user.pipe(first()).toPromise();
    const token = currentUser ? yield currentUser.getIdToken() : localStorage.getItem('token');
    return {
        headers: Object.assign({}, headers, { Authorization: token ? `Bearer ${token}` : '' })
    };
}));
