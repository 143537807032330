import {Component, HostBinding, OnInit, TemplateRef} from '@angular/core';
import {PopoverContent, PopoverRef} from './popover.ref';

@Component({
	selector: 'caroo-popover',
	templateUrl: './popover.component.html',
	styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
	@HostBinding('class.left') left = this.popoverRef.arrowPosition === 'left';
	@HostBinding('class.right') right = this.popoverRef.arrowPosition === 'right';
	renderMethod: 'template' | 'component' | 'text' = 'component';
	content: PopoverContent;
	context;

	constructor(private popoverRef: PopoverRef) {
	}

	ngOnInit() {
		this.content = this.popoverRef.content;

		if (typeof this.content === 'string') {
			this.renderMethod = 'text';
		} else if (this.content instanceof TemplateRef) {
			this.renderMethod = 'template';
			this.context = {
				close: this.popoverRef.close.bind(this.popoverRef)
			};
		}
	}
}
