import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InviteColleagueGQL, USE_BRAND_API } from '@caroo/graphql-new';

@Component({
	selector: 'caroo-invite-colleagues-dialog',
	templateUrl: './invite-colleagues-dialog.component.html',
	styleUrls: ['./invite-colleagues-dialog.component.scss']
})
export class InviteColleaguesDialogComponent {
	readonly formGroup = this.formBuilder.group({
		email0: new FormControl('', [Validators.required, Validators.email]),
	});
	private formControlCount = 1;
	private readonly formControlStatusMap = new Map<string, 'pending' | 'success' | 'failure'>();
	private readonly successfullySubmittedFormControlNames: string[] = [];
	apiErrors: { [s: string]: string } = {};
	submitting = false;

	constructor(
		private readonly formBuilder: FormBuilder,
		private readonly inviteColleagueGQL: InviteColleagueGQL,
		private readonly matDialog: MatDialog,
		private readonly matDialogRef: MatDialogRef<InviteColleaguesDialogComponent>,
		private readonly matSnackBar: MatSnackBar
	) {
	}

	get controls(): [string, FormControl][] {
		return Object.entries(this.formGroup.controls) as [string, FormControl][];
	}

	getFormControlStatus(name: string): 'pending' | 'success' | 'failure' {
		return this.formControlStatusMap.get(name);
	}

	addFormControl(): void {
		this.formGroup.addControl(`email${this.formControlCount}`, new FormControl('', [Validators.required, Validators.email]));
		this.formControlCount++;
	}

	trackFormControl(index: number): number {
		return index;
	}

	removeFormControl(name: string): void {
		this.formGroup.removeControl(name);
	}

	async sendInvites(): Promise<void> {
		this.submitting = true;
		this.apiErrors = {};
		const entries = Object.entries(this.formGroup.controls).filter(([name]) => !this.successfullySubmittedFormControlNames.includes(name));
		for (const [name] of entries) {
			this.formControlStatusMap.set(name, 'pending');
		}
		for (const [name, formControl] of entries) {
			try {
				await this.inviteColleagueGQL.mutate({input: {email: formControl.value}}, USE_BRAND_API).toPromise();
				this.formControlStatusMap.set(name, 'success');
			} catch (err) {
				this.formControlStatusMap.set(name, 'failure');
				const errors: { message: string }[] | undefined = err.graphQLErrors;
				if (errors && errors.length > 0) {
					this.apiErrors[name] = errors[0].message;
				} else {
					this.apiErrors[name] = 'An unknown error occurred';
				}
			}
		}
		for (const [name, formControl] of entries) {
			if (this.formControlStatusMap.get(name) === 'success') {
				this.successfullySubmittedFormControlNames.push(name);
				formControl.disable();
			} else {
				this.formControlStatusMap.delete(name);
			}
		}
		this.submitting = false;
		if (Object.keys(this.formGroup.controls).length === 0) {
			this.matSnackBar.open('Your colleagues have been sent emails, inviting them to join you on Caroo', 'Close', {
				duration: 3000,
			});
			this.matDialogRef.close();
		}
	}
}
