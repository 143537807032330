import {NgModule} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {ConfigService} from '@caroo/config/config.service';
import {authLink} from '@caroo/network/links/auth.link';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import {ApolloClientOptions} from 'apollo-client';
import {split} from 'apollo-link';
import {WebSocketLink} from 'apollo-link-ws';
import {getMainDefinition} from 'apollo-utilities';
import {first} from 'rxjs/operators';
import introspectionResult from '../generated/introspection-result';

const getUri = (url: string, protocol: 'http' | 'ws'): string => url.replace('http', protocol);

@NgModule({
	exports: [ApolloModule, HttpLinkModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: (angularFireAuth: AngularFireAuth, configService: ConfigService, httpLink: HttpLink) => {
				return ({
					link: authLink(angularFireAuth).concat(split(({query}) => {
						const definition = getMainDefinition(query);
						return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
					}, new WebSocketLink({
						uri: getUri(configService.graphqlApiUrl, 'ws'),
						options: {
							lazy: true,
							reconnect: true,
							connectionParams: async () => {
								const currentUser = await angularFireAuth.user.pipe(first()).toPromise();
								const token = currentUser ? await currentUser.getIdToken() : localStorage.getItem('token');
								return {
									Authorization: token ? `Bearer ${token}` : ''
								};
							}
						}
					}), split(({getContext}) => getContext()['useBrandApi'] === true, httpLink.create({uri: getUri(configService.brandApiUrl, 'http')}), httpLink.create({uri: getUri(configService.graphqlApiUrl, 'http')})))),
					cache: new InMemoryCache({
						fragmentMatcher: new IntrospectionFragmentMatcher({
							introspectionQueryResultData: introspectionResult
						})
					}),
					defaultOptions: {
						query: {
							fetchPolicy: 'no-cache'
						},
						watchQuery: {
							fetchPolicy: 'no-cache'
						},
						mutate: {
							fetchPolicy: 'no-cache'
						}
					}
				}) as ApolloClientOptions<any>;
			},
			deps: [AngularFireAuth, ConfigService, HttpLink]
		}
	]
})
export class GraphQLModule {
}
