import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resource} from '@caroo/shared/models/resource';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Location, Profile} from '../../shared/models';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {

	constructor(private http: HttpClient) {
	}

	getProfile(): Observable<Profile> {
		return this.http.get<Profile>(`${environment.baseUrl}/brands/profile`);
	}

	updateProfile(body: UpdateProfileBody): Observable<Profile> {
		return this.http.patch<Profile>(`${environment.baseUrl}/brands/profile`, body);
	}

	setProfileHeader(resource: Resource): Observable<any> {
		if (resource && (resource.type === Resource.Type.COLOUR || (resource.type === Resource.Type.IMAGE && resource.file))) {
			const data = new FormData();
			switch (resource.type) {
				case Resource.Type.COLOUR:
					data.append('colour', resource.resource);
					break;
				case Resource.Type.IMAGE:
					data.append('resource', resource.file);
					break;
			}
			return this.http.put<Profile>(`${environment.baseUrl}/brands/profile/header`, data);
		} else {
			return of({});
		}
	}

	setProfileLogo(resource: Resource): Observable<any> {
		if (resource && resource.file) {
			const data = new FormData();
			data.append('resource', resource.file);
			return this.http.put<Profile>(`${environment.baseUrl}/brands/profile/logo`, data);
		} else {
			return of({});
		}
	}

	addMedia(...resources: Resource[]): Observable<any> {
		if (resources.length === 0) {
			return of({});
		} else {
			const data = new FormData();
			let count = 0;
			resources.forEach(resource => {
				if (resource.file) {
					data.append('resource', resource.file);
				} else if (resource.type === Resource.Type.YOUTUBE) {
					data.append(`youtube-${count++}`, resource.resource);
				}
			});
			return this.http.post<Profile>(`${environment.baseUrl}/brands/profile/media`, data);
		}
	}

	removeMedia(...resources: Resource[]): Observable<any> {
		if (resources.length === 0) {
			return of({});
		} else {
			return of(resources)
				.pipe(map(rs => rs.map(r => r.id)))
				.pipe(switchMap(
					ids => forkJoin(...ids.map(id => this.http.delete<Profile>(`${environment.baseUrl}/brands/profile/media/${id}`)))
				));
		}
	}
}

export interface UpdateProfileBody {
	name?: string;
	locations?: Location[];
	companyType?: string;
	tagLine?: string;
	brandStory?: string;
	culture?: string;
	transportAndAmenities?: string;
	keywords?: number[];
	perks?: number[];
	headerVideo?: Resource;
}
