/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resource.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../pipes/safe/safe.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./resource.component";
import * as i8 from "../youtube-input-field/youtube.service";
var styles_ResourceComponent = [i0.styles];
var RenderType_ResourceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResourceComponent, data: {} });
export { RenderType_ResourceComponent as RenderType_ResourceComponent };
function View_ResourceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "colour"]], [[4, "background-color", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.resource; _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["alt", "Image"]], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵppd(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.internalConfig.image; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.resource.resource, "resourceUrl")); _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["alt", "Image"]], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵppd(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.internalConfig.image; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.resource.url, "resourceUrl")); _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["alt", "Fallback"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.fallback.resource, "resourceUrl")); _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "play"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_2 = "play"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ResourceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "image"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.showImage && _co.resource) && _co.resource.resource); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.showImage && _co.resource) && _co.resource.url); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.showImageFallback && _co.fallback); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.showVideoPlayIcon; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.internalConfig.image.container.display; _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["video", 1]], null, 3, "video", [], [[8, "controls", 0], [4, "width", null], [4, "height", null], [4, "objectFit", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵted(-1, null, [" Your browser does not support HTML videos\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.internalConfig.video.controls; var currVal_1 = _co.internalConfig.video.width; var currVal_2 = _co.internalConfig.video.height; var currVal_3 = _co.internalConfig.video.objectFit; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.resource.resource, "url")); _ck(_v, 1, 0, currVal_4); }); }
function View_ResourceComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "iframe", [["class", "youtube"], ["height", "360"], ["width", "640"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), ("https://www.youtube.com/embed/" + _co.resource.resource), "resourceUrl")); _ck(_v, 0, 0, currVal_0); }); }
export function View_ResourceComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.SafePipe, [i6.DomSanitizer]), i1.ɵqud(671088640, 1, { video: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceComponent_8)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.resource && (_co.resource["type"] === _co.Type.COLOUR)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.resource && (_co.resource["type"] === _co.Type.IMAGE)) || (_co.resource && (_co.resource["__typename"] === "ImageResource"))) || (_co.fallback && (_co.fallback.type === _co.Type.IMAGE))); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.resource && (_co.resource["type"] === _co.Type.VIDEO)); _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.resource && (_co.resource["type"] === _co.Type.YOUTUBE)) && (_co.internalConfig.youtube.showPreviewOnly === false)); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ResourceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-resource", [], null, null, null, View_ResourceComponent_0, RenderType_ResourceComponent)), i1.ɵdid(1, 704512, null, 0, i7.ResourceComponent, [i1.ChangeDetectorRef, i8.YoutubeService], null, null)], null, null); }
var ResourceComponentNgFactory = i1.ɵccf("caroo-resource", i7.ResourceComponent, View_ResourceComponent_Host_0, { resource: "resource", fallback: "fallback", config: "config" }, {}, []);
export { ResourceComponentNgFactory as ResourceComponentNgFactory };
