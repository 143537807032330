var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AngularFireAuth } from '@angular/fire/auth';
import { ConfigService } from '@caroo/config/config.service';
import { authLink } from '@caroo/network/links/auth.link';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { first } from 'rxjs/operators';
import introspectionResult from '../generated/introspection-result';
const getUri = (url, protocol) => url.replace('http', protocol);
const ɵ0 = getUri;
const ɵ1 = (angularFireAuth, configService, httpLink) => {
    return ({
        link: authLink(angularFireAuth).concat(split(({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        }, new WebSocketLink({
            uri: getUri(configService.graphqlApiUrl, 'ws'),
            options: {
                lazy: true,
                reconnect: true,
                connectionParams: () => __awaiter(this, void 0, void 0, function* () {
                    const currentUser = yield angularFireAuth.user.pipe(first()).toPromise();
                    const token = currentUser ? yield currentUser.getIdToken() : localStorage.getItem('token');
                    return {
                        Authorization: token ? `Bearer ${token}` : ''
                    };
                })
            }
        }), split(({ getContext }) => getContext()['useBrandApi'] === true, httpLink.create({ uri: getUri(configService.brandApiUrl, 'http') }), httpLink.create({ uri: getUri(configService.graphqlApiUrl, 'http') })))),
        cache: new InMemoryCache({
            fragmentMatcher: new IntrospectionFragmentMatcher({
                introspectionQueryResultData: introspectionResult
            })
        }),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache'
            },
            watchQuery: {
                fetchPolicy: 'no-cache'
            },
            mutate: {
                fetchPolicy: 'no-cache'
            }
        }
    });
};
export class GraphQLModule {
}
export { ɵ0, ɵ1 };
