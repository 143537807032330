import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class IconRegistryService {
	private readonly icons = [
		'location',
		'dashboard',
		'roles',
		'messages',
		'profile',
		'notifications',
		'send',
		'followers',
		'views',
		'matches',
		'interested',
		'suggested',
		'yes',
		'not-interested',
		'play',
		'career-highlight',
		'education-highlight',
		'personal-highlight',
		'next',
		'previous',
		'media',
		'edit',
		'remove',
		'time',
		'tick',
		'logout',
		'select-arrow',
		'delete',
		'pipeline',
		'locked',
		'content-hub',
		'error'
	];

	constructor(private readonly iconRegistry: MatIconRegistry, private readonly santisier: DomSanitizer) {
	}

	registerIcons() {
		this.icons.forEach(icon => this.iconRegistry.addSvgIcon(icon, this.santisier.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)));
	}
}
