import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DashboardService {
    constructor(http) {
        this.http = http;
    }
    getDashboard() {
        return this.http.get(`${environment.baseUrl}/dashboard2`);
    }
    getNumCandidates() {
        return this.http.get(`${environment.baseUrl}/analytics/candidates/count`);
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardService, providedIn: "root" });
