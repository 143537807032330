import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
	getAvatarForNotification,
	getContentForNotification,
	getIconForNotification,
	getLinkForNotification,
	getScoreForNotification,
	getStyledTitleForNotification
} from '@caroo/notifications/util/notification-utils';
import {FromInput} from '@caroo/shared/decorators/from-input.decorator';
import {filterDefined} from '@caroo/util/filters';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {EmployeeNotificationFragment} from '../../../../generated/graphql';

@Component({
	selector: 'caroo-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnChanges {
	@FromInput() private readonly notification$$ = new BehaviorSubject<EmployeeNotificationFragment | undefined>(undefined);
	@Input() notification?: EmployeeNotificationFragment = undefined;

	private readonly notification$ = this.notification$$.pipe(filterDefined());
	readonly avatar$ = this.notification$.pipe(map(getAvatarForNotification));
	readonly title$ = this.notification$.pipe(map(getStyledTitleForNotification));
	readonly content$ = this.notification$.pipe(map(getContentForNotification));
	readonly icon$ = this.notification$.pipe(map(getIconForNotification));
	readonly link$ = this.notification$.pipe(map(getLinkForNotification));
	readonly score$ = this.notification$.pipe(map(getScoreForNotification));

	ngOnChanges(changes: SimpleChanges) {
		// method must be defined for @FromInput to work
	}
}
