import {Component, Input} from '@angular/core';
import { ImageResource } from '@caroo/graphql-new';
import {Resource} from '@caroo/shared/models';
import {ResourceFragment} from '../../../generated/graphql';

type ResourceType = Resource | ResourceFragment | ImageResource | null;

@Component({
	selector: 'caroo-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
	@Input()
	resource?: ResourceType;
	fallback: Resource = {
		type: Resource.Type.IMAGE,
		resource: 'assets/images/anonymous-human.svg'
	};
}
