import {DashboardActions, DashboardActionTypes} from '../actions';
import {Dashboard, NumCandidates} from '../services';

export interface DashboardState {
	dashboard: Dashboard;
	numCandidates: NumCandidates;
}

export const initialState: DashboardState = {
	dashboard: null,
	numCandidates: undefined
};

export function reducer(state = initialState, action: DashboardActions): DashboardState {
	if (action.type === DashboardActionTypes.SetDashboard) {
		return {
			...state,
			dashboard: action.payload.dashboard
		};
	} else if (action.type === DashboardActionTypes.SetNumCandidates) {
		return {
			...state,
			numCandidates: action.payload.numCandidates
		};
	} else {
		return state;
	}
}
