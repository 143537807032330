import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {LottieComponent} from '@caroo/shared/lottie/lottie.component';

@NgModule({
	declarations: [LottieComponent],
	imports: [CommonModule],
	exports: [LottieComponent]
})
export class LottieModule {

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: LottieModule
		};
	}
}
