var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnChanges, SimpleChanges } from '@angular/core';
import { getAvatarForNotification, getContentForNotification, getIconForNotification, getLinkForNotification, getScoreForNotification, getStyledTitleForNotification } from '@caroo/notifications/util/notification-utils';
import { FromInput } from '@caroo/shared/decorators/from-input.decorator';
import { filterDefined } from '@caroo/util/filters';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
export class NotificationComponent {
    constructor() {
        this.notification$$ = new BehaviorSubject(undefined);
        this.notification = undefined;
        this.notification$ = this.notification$$.pipe(filterDefined());
        this.avatar$ = this.notification$.pipe(map(getAvatarForNotification));
        this.title$ = this.notification$.pipe(map(getStyledTitleForNotification));
        this.content$ = this.notification$.pipe(map(getContentForNotification));
        this.icon$ = this.notification$.pipe(map(getIconForNotification));
        this.link$ = this.notification$.pipe(map(getLinkForNotification));
        this.score$ = this.notification$.pipe(map(getScoreForNotification));
    }
    ngOnChanges(changes) {
        // method must be defined for @FromInput to work
    }
}
__decorate([
    FromInput(),
    __metadata("design:type", Object)
], NotificationComponent.prototype, "notification$$", void 0);
