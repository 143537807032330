import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';
import {AppService} from '@caroo/app.service';
import {LoadBrandSubscriptionState, SetBrandSubscriptionState} from '@caroo/payment/actions/payment.actions';
import {PaymentService} from '@caroo/payment/payment.service';
import {PaymentState} from '@caroo/payment/reducers/payment.reducer';
import {ClearProfile, LoadProfile} from '@caroo/profile/actions/profile.actions';
import {selectProfile} from '@caroo/profile/reducers';
import {ProfileState} from '@caroo/profile/reducers/profile.reducer';
import {IconRegistryService} from '@caroo/services/icon-registry.service';
import {IncompleteProfileDialogComponent} from '@caroo/shared/incomplete-profile-dialog';
import {InviteColleaguesDialogComponent} from '@caroo/shared/invite-colleagues-dialog/invite-colleagues-dialog.component';
import {Profile} from '@caroo/shared/models';
import {LoadUserProfile} from '@caroo/users/actions/user.actions';
import {UserProfile} from '@caroo/users/models/user-profile';
import {selectUserProfile} from '@caroo/users/reducers';
import {UserState} from '@caroo/users/reducers/user.reducer';
import {filterDefined} from '@caroo/util/filters';
import {Store} from '@ngrx/store';
import {Observable, Subscription, timer} from 'rxjs';
import {debounce, distinctUntilChanged, filter, first, map} from 'rxjs/operators';
import {GetNotificationsGQL} from '../generated/graphql';
import {LoadingService} from './network/loading.service';
import {MobileQueryService} from './services/mobile-query.service';

@Component({
	selector: 'caroo-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	private readonly navlessUrlPaths = [
		'login',
		'register',
		'forgot-password',
		'reset-password',
		'invites/accept',
		'auth-action',
		'admin-login',
		'external-payment'
	];
	private subscriptionStateSubscription: Subscription;
	showNav: boolean = undefined;
	LinkState = LinkState;
	linkState$ = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd),
		map(event => {
			if (event instanceof NavigationEnd) {
				if (event.url === '/') {
					return LinkState.DASHBOARD;
				} else if (event.url.startsWith('/roles')) {
					return LinkState.ROLES;
				} else if (event.url.startsWith('/pipeline')) {
					return LinkState.PIPELINE;
				} else if (event.url.startsWith('/content-hub')) {
					return LinkState.CONTENT_HUB;
				} else if (event.url.startsWith('/messages')) {
					return LinkState.MESSAGES;
				} else if (event.url.startsWith('/profile')) {
					return LinkState.PROFILE;
				} else {
					return LinkState.OTHER;
				}
			} else {
				return LinkState.OTHER;
			}
		})
	);
	loading: Observable<boolean>;

	@ViewChild(MatSidenav, {static: false})
	sidenav: MatSidenav;

	userProfile$: Observable<UserProfile>;
	profile$: Observable<Profile>;
	numUnreadNotifications$: Observable<number>;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		private loaderService: LoadingService,
		public mobileQueryService: MobileQueryService,
		private profileStore: Store<ProfileState>,
		private userStore: Store<UserState>,
		private dialog: MatDialog,
		private appService: AppService,
		private readonly paymentService: PaymentService,
		private readonly paymentStore: Store<PaymentState>,
		private readonly getNotificationsGQL: GetNotificationsGQL,
		iconRegistryService: IconRegistryService
	) {
		iconRegistryService.registerIcons();
	}

	ngOnInit() {
		this.loading = this.loaderService.loading
			.pipe(distinctUntilChanged())
			.pipe(debounce(value => timer(!value ? 500 : 0)));

		this.router.events.subscribe(value => {
			if (value instanceof NavigationEnd) {
				const previousShowNav = this.showNav;
				this.showNav = !this.navlessUrlPaths.find(path => value.url.includes(path));


				if (this.showNav === true) {
					if (this.showNav !== previousShowNav) {
						this.userStore.dispatch(new LoadUserProfile());
						this.profileStore.dispatch(new LoadProfile());
						this.paymentStore.dispatch(new LoadBrandSubscriptionState());
					}
					if (!this.subscriptionStateSubscription || this.subscriptionStateSubscription.closed) {
						this.subscriptionStateSubscription = this.paymentService
							.watchBrandSubscriptionState()
							.subscribe(message => this.paymentStore.dispatch(
								new SetBrandSubscriptionState({subscriptionState: message.data.subscriptionStateChanged})
							));
					}
				} else {
					if (this.subscriptionStateSubscription) {
						this.subscriptionStateSubscription.unsubscribe();
					}
				}
			}
		});

		this.userProfile$ = this.userStore.select(selectUserProfile);
		this.profile$ = this.profileStore.select(selectProfile);

		this.numUnreadNotifications$ = this.getNotificationsGQL.watch(undefined, {fetchPolicy: 'cache-only'}).valueChanges.pipe(
			map(result => result.data),
			filterDefined(),
			map(data => data.me),
			filterDefined(),
			map(me => me.employee.notifications),
			map(notifications => notifications.filter(notification => !notification.read).length),
		);

		this.paymentService.initChargebee();
	}

	closeSidenavIfMobile() {
		if (this.mobileQueryService.isMobile) {
			this.sidenav.close().catch(console.error);
		}
	}

	inviteColleagues() {
		this.dialog.open(InviteColleaguesDialogComponent);
	}

	goToAddRole() {
		this.profileStore.dispatch(new ClearProfile());
		this.profile$
			.pipe(filter(profile => !!profile))
			.pipe(first())
			.subscribe(profile => {
				if (!!profile.name && profile.locations.length > 0 && !!profile.logo) {
					this.router.navigateByUrl('/roles/add').catch(console.error);
				} else {
					this.dialog.open(IncompleteProfileDialogComponent);
				}
			});
		this.profileStore.dispatch(new LoadProfile());
	}
}

enum LinkState {
	DASHBOARD = 'DASHBOARD',
	ROLES = 'ROLES',
	PIPELINE = 'PIPELINE',
	CONTENT_HUB = 'CONTENT_HUB',
	MESSAGES = 'MESSAGES',
	PROFILE = 'PROFILE',
	OTHER = 'OTHER'
}
