import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class YoutubeService {

	constructor(private http: HttpClient) {
	}

	getVideoSnippet(videoId: string): Observable<any> {
		return this.http.get(`https://www.googleapis.com/youtube/v3/videos?key=AIzaSyAbhZsdHomM03L5itBLbsUv-OtpvXrFOSU&part=snippet&id=${videoId}`, {
			headers: {
				Accept: 'application/json'
			}
		});
	}
}
