<div *ngIf="resource && resource['type'] === Type.COLOUR" class="colour" [style.background-color]="resource.resource"></div>
<div class="image" *ngIf="(resource && resource['type'] === Type.IMAGE) || (resource && resource['__typename'] === 'ImageResource') || (fallback && fallback.type === Type.IMAGE)" [style.display]="internalConfig.image.container.display">
	<img *ngIf="showImage && resource && resource.resource" [src]="resource.resource | safe: 'resourceUrl'" [ngStyle]="internalConfig.image" alt="Image"/>
	<img *ngIf="showImage && resource && resource.url" [src]="resource.url | safe: 'resourceUrl'" [ngStyle]="internalConfig.image" alt="Image"/>
	<img *ngIf="showImageFallback && fallback" [src]="fallback.resource | safe: 'resourceUrl'" alt="Fallback"/>
	<mat-icon *ngIf="showVideoPlayIcon" svgIcon="play"></mat-icon>
</div>
<video *ngIf="resource && resource['type'] === Type.VIDEO" [controls]="internalConfig.video.controls" [style.width]="internalConfig.video.width" [style.height]="internalConfig.video.height"
       [style.objectFit]="internalConfig.video.objectFit" #video>
	<source [src]="resource.resource | safe: 'url'" type="video/mp4">
	Your browser does not support HTML videos
</video>
<iframe *ngIf="resource && resource['type'] === Type.YOUTUBE && internalConfig.youtube.showPreviewOnly === false" class="youtube" width="640" height="360"
        [src]="('https://www.youtube.com/embed/' + resource.resource) | safe: 'resourceUrl'"></iframe>
