/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-field.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./form-field.component";
var styles_FormFieldComponent = [i0.styles];
var RenderType_FormFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFieldComponent, data: {} });
export { RenderType_FormFieldComponent as RenderType_FormFieldComponent };
function View_FormFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "body3 length"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "/", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.length; var currVal_1 = _co.input.maxlength; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_FormFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-icon", [["class", "select-arrow mat-icon notranslate"], ["role", "img"], ["svgIcon", "select-arrow"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFieldComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "select-arrow"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.input.hasMaxLength; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_FormFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "caroo-form-field", [], null, null, null, View_FormFieldComponent_0, RenderType_FormFieldComponent)), i1.ɵdid(1, 4374528, null, 2, i5.FormFieldComponent, [], null, null), i1.ɵqud(335544320, 1, { input: 0 }), i1.ɵqud(335544320, 2, { quill: 0 })], null, null); }
var FormFieldComponentNgFactory = i1.ɵccf("caroo-form-field", i5.FormFieldComponent, View_FormFieldComponent_Host_0, {}, {}, ["*"]);
export { FormFieldComponentNgFactory as FormFieldComponentNgFactory };
