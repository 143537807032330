import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptBrandInviteGQL, USE_BRAND_API } from '@caroo/graphql-new';
import { InfoDialogComponent, InfoDialogData } from '@caroo/shared/info-dialog';
import { first } from 'rxjs/operators';
import UserCredential = firebase.auth.UserCredential;

@Component({
	selector: 'caroo-accept-invite',
	templateUrl: './accept-invite.component.html',
	styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {
	readonly lottieConfig = {
		path: 'assets/animations/logo.json',
		renderer: 'canvas',
		autoplay: true,
		loop: true
	};
	readonly email = new FormControl('', [Validators.required, Validators.email]);
	readonly password = new FormControl('', [Validators.required, Validators.minLength(6)]);
	readonly formGroup = this.formBuilder.group({
		email: this.email,
		password: this.password,
	});
	token: string;
	hide = true;

	constructor(
		private readonly acceptBrandInviteGQL: AcceptBrandInviteGQL,
		private readonly activatedRoute: ActivatedRoute,
		private readonly angularFireAuth: AngularFireAuth,
		private readonly formBuilder: FormBuilder,
		private readonly matDialog: MatDialog,
		private readonly router: Router,
	) {
	}

	ngOnInit() {
		this.activatedRoute.queryParamMap.pipe(first()).subscribe((paramMap) => {
			this.token = paramMap.get('token');
			this.email.setValue(decodeURIComponent(paramMap.get('email')));
		});
	}

	async onSubmit(): Promise<void> {
		let userCredential: UserCredential;
		try {
			userCredential = await this.angularFireAuth.auth.createUserWithEmailAndPassword(this.email.value, this.password.value);
			await userCredential.user.getIdToken(true);
		} catch (e) {
			console.error(e);
			if (userCredential) {
				await userCredential.user.delete();
			}
			this.matDialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
				data: {
					title: 'Error',
					body: `Something went wrong (${e}); please contact Caroo for assistance`,
					action: {
						text: 'Try again'
					}
				}
			});
			return;
		}
		try {
			await this.acceptBrandInviteGQL.mutate({input: {token: this.token}}, USE_BRAND_API).toPromise();
			await userCredential.user.getIdToken(true);
		} catch (e) {
			console.error(e);
			this.matDialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
				data: {
					title: 'Error',
					body: 'This invite link does not appear to be valid. Please ask the colleague that invited you to do so again',
					action: {
						text: 'Try again'
					}
				}
			});
			await userCredential.user.delete();
			return;
		}
		await this.router.navigateByUrl('');
	}
}
