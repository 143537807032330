import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import Lottie, {AnimationItem} from 'lottie-web/build/player/lottie';

@Component({
	selector: 'caroo-lottie',
	templateUrl: './lottie.component.html',
	styleUrls: ['./lottie.component.scss']
})
export class LottieComponent implements OnInit, OnDestroy {
	@Input() options: any;
	@Input() width: number;
	@Input() height: number;
	@Output() animCreated: any = new EventEmitter();

	@ViewChild('container', {static: true})
	container: ElementRef<HTMLDivElement>;

	public viewWidth: string;
	public viewHeight: string;
	private internalOptions: any;
	private animation: AnimationItem;

	ngOnInit() {
		this.internalOptions = {
			container: this.container.nativeElement,
			renderer: this.options.renderer || 'svg',
			loop: this.options.loop !== false,
			autoplay: this.options.autoplay !== false,
			autoloadSegments: this.options.autoloadSegments !== false,
			animationData: this.options.animationData,
			path: this.options.path || '',
			rendererSettings: this.options.rendererSettings || {}
		};

		this.viewWidth = this.width + 'px' || '100%';
		this.viewHeight = this.height + 'px' || '100%';

		this.animation = Lottie.loadAnimation(this.internalOptions);
		this.animCreated.emit(this.animation);
	}

	ngOnDestroy() {
		this.animation.stop();
		this.animation.destroy();
	}
}
