export interface Resource {
	id?: number;
	type: Resource.Type;
	resource: string;
	file?: File;
}

export namespace Resource {
	export enum Type {
		COLOUR = 'COLOUR',
		IMAGE = 'IMAGE',
		VIDEO = 'VIDEO',
		YOUTUBE = 'YOUTUBE',
		FILE = 'FILE'
	}
}
