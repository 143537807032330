/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "../notification/notification.component.ngfactory";
import * as i7 from "../notification/notification.component";
import * as i8 from "@ngrx-utils/store";
import * as i9 from "./notification-list.component";
import * as i10 from "../../services/notification.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../services/electron.service";
var styles_NotificationListComponent = [i0.styles];
var RenderType_NotificationListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationListComponent, data: {} });
export { RenderType_NotificationListComponent as RenderType_NotificationListComponent };
function View_NotificationListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " unread"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngLet; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "h2", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "label", [["class", "body2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markAllNotificationsAsRead() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Mark all as read"]))], function (_ck, _v) { var currVal_2 = (_v.context.ngLet > 0); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NotificationListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-notification", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNotificationClicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NotificationComponent_0, i6.RenderType_NotificationComponent)), i1.ɵdid(1, 573440, null, 0, i7.NotificationComponent, [], { notification: [0, "notification"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotificationListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "title no-notifications"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have any notifications"]))], null, null); }
function View_NotificationListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_4)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noNotifications", 2]], null, 0, null, View_NotificationListComponent_6))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf.length > 0); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NotificationListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationListComponent_1)), i1.ɵdid(2, 81920, null, 0, i8.NgLetDirective, [i1.ViewContainerRef, i1.TemplateRef], { ngLet: [0, "ngLet"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationListComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.numUnreadNotifications$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.notifications$)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NotificationListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-notification-list", [], null, null, null, View_NotificationListComponent_0, RenderType_NotificationListComponent)), i1.ɵdid(1, 245760, null, 0, i9.NotificationListComponent, [i10.NotificationService, i1.NgZone, i11.Router, i12.ElectronService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationListComponentNgFactory = i1.ɵccf("caroo-notification-list", i9.NotificationListComponent, View_NotificationListComponent_Host_0, {}, { notificationClicked: "notificationClicked" }, []);
export { NotificationListComponentNgFactory as NotificationListComponentNgFactory };
