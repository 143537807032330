import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, switchMap, throttleTime} from 'rxjs/operators';
import {ProfileActions, ProfileActionTypes, SetProfile} from '../actions/profile.actions';
import {ProfileService} from '../services/profile.service';


@Injectable()
export class ProfileEffects {
	@Effect()
	loadProfile$ = this.actions$
		.pipe(ofType(ProfileActionTypes.LoadProfile))
		.pipe(throttleTime(1000))
		.pipe(switchMap(() => this.profileService.getProfile()))
		.pipe(map(profile => new SetProfile({profile})));

	constructor(private actions$: Actions<ProfileActions>, private profileService: ProfileService) {
	}

}
