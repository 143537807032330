<ng-container>
	<caroo-lottie *ngIf="lottieOptions" [options]="lottieOptions" [width]="160" [height]="160"></caroo-lottie>
	<h1 class="heading">{{data.title}}</h1>
	<p class="body1">{{data.body}}</p>
	<a [routerLink]="data.action.route" *ngIf="data.action">
		<button caroo-primary-button (click)="close()">{{data.action.text}}</button>
	</a>
	<div class="actions" *ngIf="data.actions">
		<ng-container *ngFor="let action of data.actions">
			<a *ngIf="action.route; else noLink" [routerLink]="action.route">
				<ng-container *ngTemplateOutlet="actions"></ng-container>
			</a>
			<ng-template #noLink>
				<ng-container *ngTemplateOutlet="actions"></ng-container>
			</ng-template>
			<ng-template #actions>
				<button *ngIf="action.type == 'primary'" caroo-primary-button (click)="close()">{{action.text}}</button>
				<button *ngIf="action.type == 'secondary'" caroo-secondary-button (click)="close()">{{action.text}}</button>
			</ng-template>
		</ng-container>
	</div>
</ng-container>
