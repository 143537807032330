import {Action} from '@ngrx/store';

export enum AppActionTypes {
	LOGOUT = '[App] Logout',
	NOOP = '[App] No-op'
}

export class Logout implements Action {
	readonly type = AppActionTypes.LOGOUT;
}

export class Noop implements Action {
	readonly type = AppActionTypes.NOOP;
}

export type AppActions = Logout | Noop;
