import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Role} from '../../shared/models';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	constructor(private http: HttpClient) {
	}

	getDashboard(): Observable<Dashboard> {
		return this.http.get<Dashboard>(`${environment.baseUrl}/dashboard2`);
	}

	getNumCandidates(): Observable<NumCandidates> {
		return this.http.get<NumCandidates>(`${environment.baseUrl}/analytics/candidates/count`);
	}
}

export interface DashboardRole {
	id: number;
	title: string;
	location: string;
	newApplicants: number;
	approvedApplicants: number;
	newMessages: number;
	live: boolean;
	draft: boolean;
	saved: boolean;
	createdAt: string;
}

export interface DashboardFollowers {
	total: number;
	new: number;
}

export interface DashboardAction {
	type: string;
	percentage: number;
}

interface Statistics {
	numBrandViews: number;
	numFollowers: number;
	numRoles: number;
	numMatches: number;
	numInterested: number;
	numSuggested: number;
}

export interface Dashboard {
	statistics: Statistics;
	roles: Role[];
	followers: DashboardFollowers;
	actions: DashboardAction[];
}

export interface NumCandidates {
	total: number;
	thisWeek: number;
}
