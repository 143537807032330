var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentDetailsSentToColleagueDialogComponent } from '@caroo/payment/payment-details-sent-to-colleague-dialog';
import { PaymentService } from '@caroo/payment/payment.service';
import { getPlanText } from '@caroo/payment/util';
export class SendPaymentDetailsToColleagueDialogComponent {
    constructor(formBuilder, data, matDialog, matDialogRef, paymentService) {
        this.formBuilder = formBuilder;
        this.data = data;
        this.matDialog = matDialog;
        this.matDialogRef = matDialogRef;
        this.paymentService = paymentService;
        this.plan = new FormControl(getPlanText(this.data.plan));
        this.employeeName = new FormControl('', [Validators.required]);
        this.financierName = new FormControl('', [Validators.required]);
        this.financierEmail = new FormControl('', [Validators.required, Validators.email]);
        this.confirmFinancierEmail = new FormControl('', [Validators.required, Validators.email, (control) => {
                if (control.value !== this.financierEmail.value) {
                    return {
                        emailsMatch: false
                    };
                }
                else {
                    return null;
                }
            }]);
        this.note = new FormControl(undefined);
        this.formGroup = this.formBuilder.group({
            plan: this.plan,
            employeeName: this.employeeName,
            financierName: this.financierName,
            financierEmail: this.financierEmail,
            confirmFinancierEmail: this.confirmFinancierEmail,
            note: this.note
        });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.paymentService.requestExternalPayment({
                plan: this.data.plan,
                employeeName: this.employeeName.value,
                externalFinancierName: this.financierName.value,
                externalFinancierEmail: this.financierEmail.value,
                note: this.note.value,
            }).toPromise();
            this.matDialog.open(PaymentDetailsSentToColleagueDialogComponent);
            this.close();
        });
    }
    close() {
        this.matDialogRef.close();
    }
}
