import {NgModule} from '@angular/core';
import {UserEffects} from '@caroo/users/effects/user.effects';
import {reducer} from '@caroo/users/reducers/user.reducer';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

@NgModule({
	imports: [
		StoreModule.forFeature('users', reducer),
		EffectsModule.forFeature([UserEffects])
	]
})
export class UserStateModule {
}
