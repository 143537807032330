/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./num-candidates.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./num-candidates.component";
import * as i4 from "@ngrx/store";
var styles_NumCandidatesComponent = [i0.styles];
var RenderType_NumCandidatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NumCandidatesComponent, data: {} });
export { RenderType_NumCandidatesComponent as RenderType_NumCandidatesComponent };
function View_NumCandidatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["+", " this week"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total Candidates"]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.total; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf.thisWeek; _ck(_v, 4, 0, currVal_1); }); }
export function View_NumCandidatesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_NumCandidatesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.numCandidates$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NumCandidatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-num-candidates", [], null, null, null, View_NumCandidatesComponent_0, RenderType_NumCandidatesComponent)), i1.ɵdid(1, 245760, null, 0, i3.NumCandidatesComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NumCandidatesComponentNgFactory = i1.ɵccf("caroo-num-candidates", i3.NumCandidatesComponent, View_NumCandidatesComponent_Host_0, {}, {}, []);
export { NumCandidatesComponentNgFactory as NumCandidatesComponentNgFactory };
