import {NgModule} from '@angular/core';
import {LogoComponent} from '@caroo/shared/logo/logo.component';
import {ResourceModule} from '@caroo/shared/resource';

@NgModule({
	declarations: [LogoComponent],
	imports: [ResourceModule],
	exports: [LogoComponent]
})
export class LogoModule {
}
