var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { iif, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorage } from '../../storage/decorator';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/router";
export class AuthenticationGuard {
    constructor(angularFireAuth, router) {
        this.angularFireAuth = angularFireAuth;
        this.router = router;
    }
    canActivate(route, state) {
        return this.angularFireAuth.user
            .pipe(switchMap(user => iif(() => !!this.token || !!user || state.url.includes('register') || state.url.includes('login'), of(true), of(this.router.createUrlTree(['register'])))));
    }
}
AuthenticationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationGuard_Factory() { return new AuthenticationGuard(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.Router)); }, token: AuthenticationGuard, providedIn: "root" });
__decorate([
    LocalStorage,
    __metadata("design:type", String)
], AuthenticationGuard.prototype, "token", void 0);
