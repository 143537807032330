import {MediaMatcher} from '@angular/cdk/layout';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class MobileQueryService implements OnDestroy {
	isMobile = false;
	private readonly isMobileSubject = new BehaviorSubject(false);
	readonly isMobile$ = this.isMobileSubject.asObservable();
	readonly isNotMobile$ = this.isMobile$.pipe(map(isMobile => !isMobile));
	private readonly mobileQueryListener: (event: MediaQueryListEvent) => void;
	private mobileQuery: MediaQueryList;

	constructor(media: MediaMatcher) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this.mobileQueryListener = event => {
			this.isMobile = event.matches;
			this.isMobileSubject.next(this.isMobile);
		};
		this.mobileQuery.addListener(this.mobileQueryListener);
		this.isMobile = this.mobileQuery.matches;
		this.isMobileSubject.next(this.isMobile);
	}

	ngOnDestroy() {
		this.mobileQuery.removeListener(this.mobileQueryListener);
	}
}
