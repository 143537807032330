/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription-started-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/button/button.component.ngfactory";
import * as i5 from "../../shared/button/button.component";
import * as i6 from "./subscription-started-dialog.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@ngrx/store";
var styles_SubscriptionStartedDialogComponent = [i0.styles];
var RenderType_SubscriptionStartedDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionStartedDialogComponent, data: {} });
export { RenderType_SubscriptionStartedDialogComponent as RenderType_SubscriptionStartedDialogComponent };
function View_SubscriptionStartedDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["routerLink", "/roles/add"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, null, null, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Create a Role"]))], function (_ck, _v) { var currVal_2 = "/roles/add"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SubscriptionStartedDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["routerLink", "/profile/edit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, null, null, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Create Profile"]))], function (_ck, _v) { var currVal_2 = "/profile/edit"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SubscriptionStartedDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Success, your subscription starts today"])), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What's next?"])), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "grid big"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/free-trial-started/create-brand-profile.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Post your jobs"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/free-trial-started/notify-candidates.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Caroo will notify relevant candidates"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/free-trial-started/start-chatting.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Start chatting"])), (_l()(), i1.ɵeld(17, 0, null, null, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["caroo-secondary-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(19, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Continue browsing Caroo"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SubscriptionStartedDialogComponent_1)), i1.ɵdid(22, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["createProfile", 2]], null, 0, null, View_SubscriptionStartedDialogComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform(_co.profileCreated$)); var currVal_1 = i1.ɵnov(_v, 24); _ck(_v, 22, 0, currVal_0, currVal_1); }, null); }
export function View_SubscriptionStartedDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-subscription-started-dialog", [], null, null, null, View_SubscriptionStartedDialogComponent_0, RenderType_SubscriptionStartedDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.SubscriptionStartedDialogComponent, [i7.MatDialogRef, i8.Store], null, null)], null, null); }
var SubscriptionStartedDialogComponentNgFactory = i1.ɵccf("caroo-subscription-started-dialog", i6.SubscriptionStartedDialogComponent, View_SubscriptionStartedDialogComponent_Host_0, {}, {}, []);
export { SubscriptionStartedDialogComponentNgFactory as SubscriptionStartedDialogComponentNgFactory };
