import {AfterViewInit, Component, ContentChild, OnDestroy} from '@angular/core';
import {QuillEditorComponent} from 'ngx-quill';
import {Subscription} from 'rxjs';
import {InputDirective} from '../input';

@Component({
	selector: 'caroo-form-field',
	templateUrl: './form-field.component.html',
	styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements AfterViewInit, OnDestroy {
	private subscriptions: Subscription[] = [];
	@ContentChild(InputDirective, {static: true}) input: InputDirective;
	@ContentChild(QuillEditorComponent, {static: true}) quill?: QuillEditorComponent;

	length = 0;

	ngAfterViewInit() {
		const input = this.input;
		if (!!input) {
			if (input.hasMaxLength) {
				this.subscriptions.push(input.length$.subscribe(length => this.length = length));
			}
		}
		if (!!this.input && this.input.elementRef.nativeElement instanceof HTMLSelectElement) {
			const select = this.input.elementRef.nativeElement as HTMLSelectElement;
			select.style.color = getComputedStyle(select.options[0], null).color;
			select.setAttribute('data-selected', 'false');
			select.onchange = () => {
				select.style.color = getComputedStyle(select.options[select.selectedIndex], null).color;
				select.setAttribute('data-selected', 'true');
			};
		}
		if ((this.input.elementRef.nativeElement.tagName as string).match(/quill-editor/i)) {
			const element = this.input.elementRef.nativeElement as HTMLElement;
			element.setAttribute('data-has-content', 'false');
			this.subscriptions.push(this.quill.onContentChanged.subscribe(({text}) => {
				element.setAttribute('data-has-content', String(/\S+.*/.test(text)));
			}));
		}
	}

	ngOnDestroy() {
		if (this.subscriptions) {
			this.subscriptions.forEach(subscription => subscription.unsubscribe());
		}
	}
}
