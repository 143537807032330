import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/icon";
import * as i2 from "@angular/platform-browser";
export class IconRegistryService {
    constructor(iconRegistry, santisier) {
        this.iconRegistry = iconRegistry;
        this.santisier = santisier;
        this.icons = [
            'location',
            'dashboard',
            'roles',
            'messages',
            'profile',
            'notifications',
            'send',
            'followers',
            'views',
            'matches',
            'interested',
            'suggested',
            'yes',
            'not-interested',
            'play',
            'career-highlight',
            'education-highlight',
            'personal-highlight',
            'next',
            'previous',
            'media',
            'edit',
            'remove',
            'time',
            'tick',
            'logout',
            'select-arrow',
            'delete',
            'pipeline',
            'locked',
            'content-hub',
            'error'
        ];
    }
    registerIcons() {
        this.icons.forEach(icon => this.iconRegistry.addSvgIcon(icon, this.santisier.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)));
    }
}
IconRegistryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IconRegistryService_Factory() { return new IconRegistryService(i0.ɵɵinject(i1.MatIconRegistry), i0.ɵɵinject(i2.DomSanitizer)); }, token: IconRegistryService, providedIn: "root" });
