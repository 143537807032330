<a *ngIf="notification" [routerLink]="link$ | async" [class.unread]="!notification.read" mat-ripple>
	<div class="media">
		<div>
			<caroo-avatar [resource]="avatar$ | async"></caroo-avatar>
			<mat-icon [svgIcon]="icon$ | async"></mat-icon>
		</div>
	</div>
	<h3 class="body1" [innerHTML]="title$ | async"></h3>
	<h4 class="body2">{{content$ | async}}</h4>
	<label class="body3">{{notification.createdAt | timeAgo | async}}</label>
	<div class="score" *ngIf="score$ | async as score">
		<caroo-score-indicator [score]="score"></caroo-score-indicator>
	</div>
</a>
