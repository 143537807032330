import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PaymentService} from '@caroo/payment/payment.service';
import {SendPaymentDetailsToColleagueDialogComponent, SendPaymentDetailsToColleagueDialogData} from '@caroo/payment/send-payment-details-to-colleague-dialog';
import {Plan, SubscriptionState} from '../../../generated/graphql';

@Component({
	selector: 'caroo-payment-info-dialog',
	templateUrl: './payment-info-dialog.component.html',
	styleUrls: ['./payment-info-dialog.component.scss']
})
export class PaymentInfoDialogComponent {
	subscriptionState: SubscriptionState;

	Plan = Plan;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: { subscriptionState: SubscriptionState },
		private readonly matDialog: MatDialog,
		private readonly matDialogRef: MatDialogRef<PaymentInfoDialogComponent>,
		private readonly paymentService: PaymentService
	) {
		this.subscriptionState = data.subscriptionState;
	}

	startSubscription(plan: Plan) {
		if (this.subscriptionState === SubscriptionState.CANCELLED) {
			this.paymentService.checkoutExistingSubscription(plan);
		} else {
			this.paymentService.checkoutNewSubscription(plan);
		}
		this.close();
	}

	sendPaymentDetailsToColleague(plan: Plan): void {
		this.close();
		this.matDialog.open<SendPaymentDetailsToColleagueDialogComponent, SendPaymentDetailsToColleagueDialogData>(
			SendPaymentDetailsToColleagueDialogComponent,
			{
				data: {
					plan
				}
			});
	}

	close() {
		this.matDialogRef.close();
	}
}
