/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../resource/resource.component.ngfactory";
import * as i3 from "../resource/resource.component";
import * as i4 from "../youtube-input-field/youtube.service";
import * as i5 from "./avatar.component";
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
export function View_AvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "caroo-resource", [], null, null, null, i2.View_ResourceComponent_0, i2.RenderType_ResourceComponent)), i1.ɵdid(2, 704512, null, 0, i3.ResourceComponent, [i1.ChangeDetectorRef, i4.YoutubeService], { resource: [0, "resource"], fallback: [1, "fallback"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.fallback; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-avatar", [], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 49152, null, 0, i5.AvatarComponent, [], null, null)], null, null); }
var AvatarComponentNgFactory = i1.ɵccf("caroo-avatar", i5.AvatarComponent, View_AvatarComponent_Host_0, { resource: "resource" }, {}, ["caroo-activity-indicator"]);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
