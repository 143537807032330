import {getSelectors, RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface RouterState {
	router: RouterReducerState;
}

export const selectRouter = createFeatureSelector<RouterState, RouterReducerState>('router');

const {selectUrl} = getSelectors(selectRouter);

export const selectRouterUrl = createSelector(selectUrl, url => url);
