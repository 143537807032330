import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PopoverComponent} from './popover.component';
import {PopoverDirective} from './popover.directive';
import {PopoverService} from './popover.service';

@NgModule({
	declarations: [PopoverComponent, PopoverDirective],
	exports: [
		OverlayModule,
		PopoverComponent,
		PopoverDirective
	],
	imports: [
		CommonModule
	],
	entryComponents: [PopoverComponent],
	providers: [PopoverService]
})
export class PopoverModule {
}
