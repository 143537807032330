import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@caroo/shared/button/button.module';
import {IncompleteProfileDialogComponent} from '@caroo/shared/incomplete-profile-dialog/incomplete-profile-dialog.component';

@NgModule({
	declarations: [IncompleteProfileDialogComponent],
	imports: [
		ButtonModule,
		RouterModule
	],
	exports: [IncompleteProfileDialogComponent],
	entryComponents: [IncompleteProfileDialogComponent]
})
export class IncompleteProfileDialogModule {
}
