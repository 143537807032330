import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {PaymentButtonModule} from '@caroo/payment/buttons/payment-button.module';
import {PaymentInfoDialogComponent} from '@caroo/payment/payment-info-dialog/payment-info-dialog.component';
import {SendPaymentDetailsToColleagueDialogModule} from '@caroo/payment/send-payment-details-to-colleague-dialog';
import {ButtonModule} from '@caroo/shared/button/button.module';
import {ScrollModule} from '@caroo/shared/scroll';

@NgModule({
	imports: [
		ButtonModule,
		MatDialogModule,
		MatIconModule,
		PaymentButtonModule,
		SendPaymentDetailsToColleagueDialogModule,
		ScrollModule
	],
	declarations: [PaymentInfoDialogComponent],
	entryComponents: [PaymentInfoDialogComponent],
	exports: [PaymentInfoDialogComponent]
})
export class PaymentInfoDialogModule {
}
