import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
	selector: 'caroo-score-indicator',
	templateUrl: './score-indicator.component.html',
	styleUrls: ['./score-indicator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreIndicatorComponent {
	@Input()
	score: number;

	@HostBinding('class')
	get cssClass(): string {
		const score = this.score === 0 ? 0 : (this.score || -1);
		if (score <= 33) {
			return 'low';
		} else if (score <= 66) {
			return 'medium';
		} else {
			return 'high';
		}
	}
}
