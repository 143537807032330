import {NgModule} from '@angular/core';
import {AvatarComponent} from '@caroo/shared/avatar/avatar.component';
import {ResourceModule} from '@caroo/shared/resource';

@NgModule({
	declarations: [AvatarComponent],
	imports: [ResourceModule],
	exports: [AvatarComponent]
})
export class AvatarModule {
}
