import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ProfileEffects} from './effects/profile.effects';
import {reducer} from './reducers/profile.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('profile', reducer),
		EffectsModule.forFeature([ProfileEffects])
	]
})
export class ProfileStateModule {
}
