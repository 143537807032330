var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '@caroo/config/config.service';
import { SubscriptionStartedDialogComponent } from '@caroo/payment/subscription-started-dialog/subscription-started-dialog.component';
import { extractData } from '@caroo/util/graphql';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { CreateChargebeeHostedPageForExternalFinancierGQL, CreateChargebeePortalSessionForExternalFinancierGQL, CreateHostedPageGQL, CreateOfflineSubscriptionGQL, CreatePortalSessionGQL, GetBrandSubscriptionStateGQL, HostedPageType, NotifyEmployeeOfExternalPaymentGQL, ReactivateOfflineSubscriptionGQL, RequestExternalPaymentGQL, SubscriptionStateChangedGQL } from '../../generated/graphql';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "../../generated/graphql";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngrx/store";
export class PaymentService {
    constructor(configService, createHostedPageGql, createOfflineSubscriptionGQL, createPortalSessionGql, getBrandSubscriptionStateGql, matDialog, ngZone, subscriptionStateChangedGQL, paymentStore, reactivateOfflineSubscriptionGQL, requestExternalPaymentGQL, createChargebeeHostedPageForExternalFinancierGQL, createChargebeePortalSessionForExternalFinancierGQL, notifyEmployeeOfExternalPaymentGQL) {
        this.configService = configService;
        this.createHostedPageGql = createHostedPageGql;
        this.createOfflineSubscriptionGQL = createOfflineSubscriptionGQL;
        this.createPortalSessionGql = createPortalSessionGql;
        this.getBrandSubscriptionStateGql = getBrandSubscriptionStateGql;
        this.matDialog = matDialog;
        this.ngZone = ngZone;
        this.subscriptionStateChangedGQL = subscriptionStateChangedGQL;
        this.paymentStore = paymentStore;
        this.reactivateOfflineSubscriptionGQL = reactivateOfflineSubscriptionGQL;
        this.requestExternalPaymentGQL = requestExternalPaymentGQL;
        this.createChargebeeHostedPageForExternalFinancierGQL = createChargebeeHostedPageForExternalFinancierGQL;
        this.createChargebeePortalSessionForExternalFinancierGQL = createChargebeePortalSessionForExternalFinancierGQL;
        this.notifyEmployeeOfExternalPaymentGQL = notifyEmployeeOfExternalPaymentGQL;
        this.newCheckoutSuccess = false;
    }
    initChargebee() {
        Chargebee.init({
            site: this.configService.chargebeeSite
        });
        this.chargebee = Chargebee.getInstance();
    }
    createHostedPage(type, plan) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createHostedPageGql
                .mutate({ type, plan })
                .pipe(first())
                .pipe(extractData('createChargebeeHostedPage'))
                .toPromise();
        });
    }
    checkoutNewSubscription(plan) {
        this.newCheckoutSuccess = false;
        this.chargebee.openCheckout({
            hostedPage: () => this.createHostedPage(HostedPageType.CHECKOUT_NEW, plan),
            success: () => this.newCheckoutSuccess = true,
            close: () => {
                if (this.newCheckoutSuccess === true) {
                    this.ngZone.run(() => this.matDialog.open(SubscriptionStartedDialogComponent));
                }
            }
        });
    }
    createHostedPageForExternalFinancier(token, type, plan) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createChargebeeHostedPageForExternalFinancierGQL
                .mutate({ input: { token, type, plan } })
                .pipe(first())
                .pipe(extractData('createChargebeeHostedPageForExternalFinancier'))
                .toPromise();
        });
    }
    checkoutSubscriptionForExternalFinancier(token, type, plan) {
        return new Promise(resolve => {
            let success = false;
            this.chargebee.openCheckout({
                hostedPage: () => this.createHostedPageForExternalFinancier(token, type, plan),
                success: () => success = true,
                close: () => resolve(success)
            });
        });
    }
    notifyEmployeeOfExternalPayment(token) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.notifyEmployeeOfExternalPaymentGQL.mutate({ input: { token } }).toPromise();
        });
    }
    checkoutExistingSubscription(plan) {
        this.chargebee.openCheckout({
            hostedPage: () => this.createHostedPage(HostedPageType.CHECKOUT_EXISTING, plan)
        });
    }
    managePaymentSources() {
        this.chargebee.openCheckout({
            hostedPage: () => this.createHostedPage(HostedPageType.MANAGE_PAYMENT_SOURCES)
        });
    }
    createPortalSession() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createPortalSessionGql
                .mutate()
                .pipe(first())
                .pipe(extractData('createChargebeePortalSession'))
                .toPromise();
        });
    }
    openChargebeePortal() {
        this.chargebee.setPortalSession(() => this.createPortalSession());
        const portal = this.chargebee.createChargebeePortal();
        portal.open();
    }
    createPortalSessionForExternalFinancier(token) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createChargebeePortalSessionForExternalFinancierGQL
                .mutate({ input: { token } })
                .pipe(first())
                .pipe(extractData('createChargebeePortalSessionForExternalFinancier'))
                .toPromise();
        });
    }
    openChargebeePortalForExternalFinancier(token) {
        this.chargebee.setPortalSession(() => this.createPortalSessionForExternalFinancier(token));
        const portal = this.chargebee.createChargebeePortal();
        portal.open();
    }
    getBrandSubscriptionState() {
        return this.getBrandSubscriptionStateGql
            .fetch()
            .pipe(extractData('me'), map(me => me.employee.brand.subscriptionState));
    }
    watchBrandSubscriptionState() {
        return this.subscriptionStateChangedGQL.subscribe();
    }
    createOfflineSubscription(plan) {
        return this.createOfflineSubscriptionGQL
            .mutate({ plan })
            .pipe(extractData('createOfflineSubscription'), map(data => data.success));
    }
    reactivateOfflineSubscription() {
        return this.reactivateOfflineSubscriptionGQL
            .mutate()
            .pipe(extractData('reactivateOfflineSubscription'), map(data => data.success));
    }
    requestExternalPayment(input) {
        return this.requestExternalPaymentGQL
            .mutate({ input }).pipe(extractData('requestExternalPayment'), map(requestExternalPayment => requestExternalPayment.success));
    }
}
PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.CreateHostedPageGQL), i0.ɵɵinject(i2.CreateOfflineSubscriptionGQL), i0.ɵɵinject(i2.CreatePortalSessionGQL), i0.ɵɵinject(i2.GetBrandSubscriptionStateGQL), i0.ɵɵinject(i3.MatDialog), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.SubscriptionStateChangedGQL), i0.ɵɵinject(i4.Store), i0.ɵɵinject(i2.ReactivateOfflineSubscriptionGQL), i0.ɵɵinject(i2.RequestExternalPaymentGQL), i0.ɵɵinject(i2.CreateChargebeeHostedPageForExternalFinancierGQL), i0.ɵɵinject(i2.CreateChargebeePortalSessionForExternalFinancierGQL), i0.ɵɵinject(i2.NotifyEmployeeOfExternalPaymentGQL)); }, token: PaymentService, providedIn: "root" });
