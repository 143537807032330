<section>
	<caroo-lottie [options]="lottieConfig" [width]="500" [height]="500"></caroo-lottie>
</section>
<section>
	<div class="form">
		<img src="assets/images/logo.svg" alt="logo"/>
		<h1>Welcome to Caroo!</h1>
		<form [formGroup]="formGroup" (submit)="onSubmit()">
			<caroo-form-field>
				<label for="email" carooInputLabel>Enter your work email address</label>
				<input id="email" [formControl]="email" required carooInput/>
			</caroo-form-field>
			<div class="password-container">
				<caroo-form-field>
					<label for="password" carooInputLabel>Type a secure password</label>
					<input id="password" [formControl]="password" [type]="hide ? 'password' : 'text'" required carooInput/>
				</caroo-form-field>
				<mat-icon (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
			</div>
			<button type="submit" caroo-primary-button [disabled]="formGroup.invalid">Let's get started!</button>
		</form>
	</div>
	<p class="body2 bottom terms-and-privacy">
		<a href="https://caroo.co.uk/pages/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a
		href="https://caroo.co.uk/pages/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
	</p>
</section>
