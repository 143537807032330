import {Directive, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Directive({
	selector: `
	input[carooInput],
	textarea[carooInput],
	select[carooInput],
	ng5-slider[carooInput],
	caroo-radio-group[carooInput],
	quill-editor[carooInput]`
})
export class InputDirective implements OnChanges {
	@Input() maxlength: string;
	@Input() ngModel: string;

	hasMaxLength = false;

	private lengthBehaviourSubject = new BehaviorSubject<number>(0);
	length$ = this.lengthBehaviourSubject.asObservable();

	constructor(public elementRef: ElementRef) {
	}

	private handleMaxLengthChange(change: SimpleChange) {
		const max = Number(change.currentValue);
		if (!!max) {
			this.hasMaxLength = true;
		}
	}

	private handleNgModelChange(change: SimpleChange) {
		if (this.hasMaxLength && change.currentValue !== undefined && change.currentValue !== null) {
			this.lengthBehaviourSubject.next((change.currentValue as string).length);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		const maxLengthChange = changes['maxlength'];
		if (!!maxLengthChange) {
			this.handleMaxLengthChange(maxLengthChange);
		}
		const ngModelChange = changes['ngModel'];
		if (!!ngModelChange) {
			this.handleNgModelChange(ngModelChange);
		}
	}
}
