/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/core";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "mat-ripple"], ["mat-ripple", ""]], [[2, "mat-ripple-unbounded", null]], null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.MatRipple, [i1.ElementRef, i1.NgZone, i3.Platform, [2, i2.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.rippleColour; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).unbounded; _ck(_v, 2, 0, currVal_0); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["caroo-primary-button", ""]], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("\n\tbutton[caroo-primary-button],\n\tbutton[caroo-secondary-button],\n\tbutton[caroo-tertiary-button],\n\tbutton[caroo-add-role-button],\n\tbutton[caroo-interested-button],\n\tbutton[caroo-not-interested-button],\n\tbutton[caroo-message-button],\n\tbutton[caroo-danger-button],\n\tbutton[caroo-dark-button]\n\t", i5.ButtonComponent, View_ButtonComponent_Host_0, { rippleColour: "rippleColour" }, {}, ["*"]);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
