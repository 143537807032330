import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NotificationModule} from '@caroo/notifications/components/notification';
import {NotificationListComponent} from '@caroo/notifications/components/notification-list/notification-list.component';
import {NgLetModule} from '@ngrx-utils/store';

@NgModule({
	declarations: [NotificationListComponent],
	imports: [
		CommonModule,
		MatToolbarModule,
		NgLetModule,
		NotificationModule
	],
	exports: [NotificationListComponent]
})
export class NotificationListModule {
}
