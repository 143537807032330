import {AppActionTypes} from '@caroo/store/actions/app.actions';
import {ActionReducer} from '@ngrx/store';

export * from './app.reducer';

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
	return function (state, action) {
		if (action.type === AppActionTypes.LOGOUT) {
			state = undefined;
		}
		return reducer(state, action);
	};
}
