import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@caroo/shared/button/button.module';
import {LottieModule} from '@caroo/shared/lottie';
import {PaymentDetailsSentToColleagueDialogComponent} from './payment-details-sent-to-colleague-dialog.component';

@NgModule({
	imports: [ButtonModule, LottieModule, MatIconModule, RouterModule],
	declarations: [PaymentDetailsSentToColleagueDialogComponent],
	entryComponents: [PaymentDetailsSentToColleagueDialogComponent],
	exports: [PaymentDetailsSentToColleagueDialogComponent]
})
export class PaymentDetailsSentToColleagueDialogModule {
}
