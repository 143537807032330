import {Interest, Location, Perk, Resource} from './';

export class Profile {
	id: number;
	name?: string;
	locations: Location[];
	companyType?: string;
	tagLine?: string;
	keywords?: Interest[];
	header?: Resource;
	headerVideo?: Resource;
	logo?: Resource;
	brandStory?: string;
	culture?: string;
	transportAndAmenities?: string;
	perks?: Perk[];
	media?: Resource[];
	completion?: Profile.Completion;
	createdAt?: string;
	updatedAt?: string;
}

export namespace Profile {

	export interface Completion {
		percentage: number;
		actions: Completion.Action[];
	}

	export namespace Completion {
		export enum Action {
			ADD_NAME = 'ADD_NAME',
			ADD_LOCATION = 'ADD_LOCATION',
			ADD_TAG_LINE = 'ADD_TAG_LINE',
			ADD_HEADER = 'ADD_HEADER',
			ADD_HEADER_VIDEO = 'ADD_HEADER_VIDEO',
			ADD_LOGO = 'ADD_LOGO',
			ADD_BRAND_STORY = 'ADD_BRAND_STORY',
			ADD_CULTURE = 'ADD_CULTURE',
			ADD_TRANSPORT_AND_AMENITIES = 'ADD_TRANSPORT_AND_AMENITIES',
			ADD_KEYWORDS = 'ADD_KEYWORDS',
			ADD_PERKS = 'ADD_PERKS',
			ADD_MEDIA = 'ADD_MEDIA'
		}
	}
}
