import {SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';

export function FromInput() {
	return function (target: object, propertyKey: string) {
		const original = target.constructor.prototype['ngOnChanges'] as (changes: SimpleChanges) => void;
		target.constructor.prototype['ngOnChanges'] = function (changes: SimpleChanges) {
			if (typeof original === 'function') {
				original.apply(this, [changes]);
			}
			const property = this[propertyKey];
			if (property instanceof Subject) {
				const change = changes[propertyKey.replace(/\$/g, '')];
				if (change) {
					const currentValue = change.currentValue;
					if (currentValue) {
						property.next(currentValue);
					}
				}
			}
		};
	};
}
