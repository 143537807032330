import {NgModule} from '@angular/core';
import {NotificationListModule} from '@caroo/notifications/components/notification-list';

@NgModule({
	imports: [
		NotificationListModule
	],
	exports: [NotificationListModule],
	providers: []
})
export class NotificationModule {
}
