import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadNumCandidates} from '@caroo/dashboard/actions';
import {selectNumCandidates} from '@caroo/dashboard/reducers';
import {DashboardState} from '@caroo/dashboard/reducers/dashboard.reducer';
import {Store} from '@ngrx/store';

@Component({
	selector: 'caroo-num-candidates',
	templateUrl: './num-candidates.component.html',
	styleUrls: ['./num-candidates.component.scss']
})
export class NumCandidatesComponent implements OnInit, OnDestroy {
	private intervalId: any;
	numCandidates$ = this.dashboardStore.select(selectNumCandidates);

	constructor(private readonly dashboardStore: Store<DashboardState>) {
	}

	ngOnInit() {
		this.dashboardStore.dispatch(new LoadNumCandidates());
		this.intervalId = setInterval(() => this.dashboardStore.dispatch(new LoadNumCandidates()), 5 * 60 * 1000);
	}

	ngOnDestroy() {
		clearInterval(this.intervalId);
	}
}
