import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GetNotificationsGQL, NotificationReceivedGQL } from '../../../generated/graphql';
import * as i0 from "@angular/core";
import * as i1 from "../../../generated/graphql";
import * as i2 from "@angular/common/http";
export class NotificationService {
    constructor(getNotificationsGQL, notificationReceivedGQL, http) {
        this.getNotificationsGQL = getNotificationsGQL;
        this.notificationReceivedGQL = notificationReceivedGQL;
        this.http = http;
    }
    getNotifications() {
        return this.getNotificationsGQL.watch(undefined, { fetchPolicy: 'cache-and-network' });
    }
    subscribeToNewNotifications() {
        return this.notificationReceivedGQL.subscribe();
    }
    markAllNotificationsAsRead() {
        return this.http.put(`${environment.baseUrl}/notifications/all/read`, undefined);
    }
    getNumUnreadNotifications() {
        return this.http.get(`${environment.baseUrl}/notifications/unread/count`);
    }
    markNotificationAsRead(id) {
        return this.http.post(`${environment.baseUrl}/notifications/${id}/read`, null).toPromise();
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.GetNotificationsGQL), i0.ɵɵinject(i1.NotificationReceivedGQL), i0.ɵɵinject(i2.HttpClient)); }, token: NotificationService, providedIn: "root" });
