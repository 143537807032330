import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InfoDialogData} from '@caroo/shared/info-dialog/info-dialog-data';

@Component({
	selector: 'caroo-info-dialog',
	templateUrl: './info-dialog.component.html',
	styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
	readonly lottieOptions;

	constructor(@Inject(MAT_DIALOG_DATA) public data: InfoDialogData, private dialogRef: MatDialogRef<InfoDialogComponent>) {
		if (data.animation) {
			this.lottieOptions = {
				path: `assets/animations/${data.animation}.json`,
				renderer: 'canvas',
				autoplay: true,
				loop: true
			};
		}
	}

	close() {
		this.dialogRef.close();
	}
}
