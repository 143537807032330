import { HttpClient } from '@angular/common/http';
import { Resource } from '@caroo/shared/models/resource';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProfileService {
    constructor(http) {
        this.http = http;
    }
    getProfile() {
        return this.http.get(`${environment.baseUrl}/brands/profile`);
    }
    updateProfile(body) {
        return this.http.patch(`${environment.baseUrl}/brands/profile`, body);
    }
    setProfileHeader(resource) {
        if (resource && (resource.type === Resource.Type.COLOUR || (resource.type === Resource.Type.IMAGE && resource.file))) {
            const data = new FormData();
            switch (resource.type) {
                case Resource.Type.COLOUR:
                    data.append('colour', resource.resource);
                    break;
                case Resource.Type.IMAGE:
                    data.append('resource', resource.file);
                    break;
            }
            return this.http.put(`${environment.baseUrl}/brands/profile/header`, data);
        }
        else {
            return of({});
        }
    }
    setProfileLogo(resource) {
        if (resource && resource.file) {
            const data = new FormData();
            data.append('resource', resource.file);
            return this.http.put(`${environment.baseUrl}/brands/profile/logo`, data);
        }
        else {
            return of({});
        }
    }
    addMedia(...resources) {
        if (resources.length === 0) {
            return of({});
        }
        else {
            const data = new FormData();
            let count = 0;
            resources.forEach(resource => {
                if (resource.file) {
                    data.append('resource', resource.file);
                }
                else if (resource.type === Resource.Type.YOUTUBE) {
                    data.append(`youtube-${count++}`, resource.resource);
                }
            });
            return this.http.post(`${environment.baseUrl}/brands/profile/media`, data);
        }
    }
    removeMedia(...resources) {
        if (resources.length === 0) {
            return of({});
        }
        else {
            return of(resources)
                .pipe(map(rs => rs.map(r => r.id)))
                .pipe(switchMap(ids => forkJoin(...ids.map(id => this.http.delete(`${environment.baseUrl}/brands/profile/media/${id}`)))));
        }
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient)); }, token: ProfileService, providedIn: "root" });
