import {Injectable} from '@angular/core';
import {ApiUrl, BrandApiUrl, ChargebeeSite, Config, GoogleApisKey, GraphqlApiUrl, SocketUrl} from '@caroo/config/config';
import {environment} from '../../environments/environment';
import {DemoFirebaseConfig, DevFirebaseConfig, FirebaseConfig, ProductionFirebaseConfig, StagingFirebaseConfig} from '../../environments/firebase-config';

type Environment =
	| 'development'
	| 'staging'
	| 'demo'
	| 'production';

interface Environments {
	[s: string]: Environment;
}

type ConfigMap = { [e in Environment]: Config; };

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	private readonly environments: Environments = {
		'localhost:4200': 'staging',
		'192.168.1.2:4200': 'development',
		'': 'staging',
		'portal.qa.caroo.co.uk': 'staging',
		'portal-qa.caroo.web.app': 'staging',
		'portal-qa-caroo.firebaseapp.com': 'staging',
		'portal.staging.caroo.co.uk': 'staging',
		'portal-staging-caroo.web.app': 'staging',
		'portal-staging-caroo.firebaseapp.com': 'staging',
		'portal.demo.caroo.co.uk': 'demo',
		'portal-demo-caroo.web.app': 'demo',
		'portal-demo-caroo.firebaseapp.com': 'demo',
		'portal.caroo.co.uk': 'production',
		'portal-caroo.web.app': 'production',
		'portal-caroo.firebaseapp.com': 'production'
	};
	private readonly configs: ConfigMap = {
		development: {
			apiUrl: 'https://caroo-legacy.eu.ngrok.io/v1',
			graphqlApiUrl: 'https://caroo.eu.ngrok.io/graphql',
			brandApiUrl: 'https://localhost:3333/graphql',
			socketUrl: 'http://localhost:3000',
			googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
			chargebeeSite: 'caroo-test',
			firebase: new DevFirebaseConfig()
		},
		staging: {
			apiUrl: 'https://api.staging.caroo.co.uk/v1',
			graphqlApiUrl: 'https://graphql-api.staging.caroo.co.uk/graphql',
			brandApiUrl: 'https://brand-api.staging.caroo.co.uk/graphql',
			socketUrl: 'https://api.staging.caroo.co.uk',
			googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
			chargebeeSite: 'caroo-test',
			firebase: new StagingFirebaseConfig()
		},
		demo: {
			apiUrl: 'https://api.demo.caroo.co.uk/v1',
			graphqlApiUrl: 'https://graphql-api.demo.caroo.co.uk/graphql',
			brandApiUrl: 'https://brand-api.demo.caroo.co.uk/graphql',
			socketUrl: 'https://api.demo.caroo.co.uk',
			googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
			chargebeeSite: 'caroo-test',
			firebase: new DemoFirebaseConfig()
		},
		production: {
			apiUrl: 'https://api.caroo.co.uk/v1',
			graphqlApiUrl: 'https://graphql-api.caroo.co.uk/graphql',
			brandApiUrl: 'https://brand-api.caroo.co.uk/graphql',
			socketUrl: 'https://api.caroo.co.uk',
			googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
			chargebeeSite: 'caroo',
			firebase: new ProductionFirebaseConfig()
		}
	};

	get environment(): Environment {
		return this.environments[window.location.host];
	}

	private get config(): Config {
		return this.configs[this.environment];
	}

	get apiUrl(): ApiUrl {
		return this.config.apiUrl;
	}

	get graphqlApiUrl(): GraphqlApiUrl {
		return this.config.graphqlApiUrl;
	}

	get brandApiUrl(): BrandApiUrl {
		return this.config.brandApiUrl;
	}

	get socketUrl(): SocketUrl {
		return this.config.socketUrl;
	}

	get googleApisKey(): GoogleApisKey {
		return this.config.googleApisKey;
	}

	get chargebeeSite(): ChargebeeSite {
		return this.config.chargebeeSite;
	}

	get firebase(): FirebaseConfig {
		return this.config.firebase;
	}

	initEnvironment() {
		environment.baseUrl = this.apiUrl;
		environment.graphqlApiUrl = this.graphqlApiUrl;
		environment.socketUrl = this.socketUrl;
		environment.googleApisKey = this.googleApisKey;
		environment.firebase = this.firebase;
	}
}
