<ng-container>
	<div class="top">
		<h1>Success, your subscription starts today</h1>
	</div>
	<div class="middle">
		<h2>What's next?</h2>
		<div class="grid big">
			<img src="assets/images/free-trial-started/create-brand-profile.svg" alt=""/>
			<p>Post your jobs</p>
			<img src="assets/images/free-trial-started/notify-candidates.svg" alt=""/>
			<p>Caroo will notify relevant candidates</p>
			<img src="assets/images/free-trial-started/start-chatting.svg" alt=""/>
			<p>Start chatting</p>
		</div>
	</div>
	<div class="actions">
		<button caroo-secondary-button (click)="close()">Continue browsing Caroo</button>
		<a routerLink="/roles/add" *ngIf="profileCreated$ | async else createProfile" (click)="close()">
			<button caroo-primary-button>Create a Role</button>
		</a>
		<ng-template #createProfile>
			<a routerLink="/profile/edit" (click)="close()">
				<button caroo-primary-button>Create Profile</button>
			</a>
		</ng-template>
	</div>
</ng-container>
