import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
	selector: 'caroo-incomplete-profile-dialog',
	templateUrl: './incomplete-profile-dialog.component.html',
	styleUrls: ['./incomplete-profile-dialog.component.scss']
})
export class IncompleteProfileDialogComponent {

	constructor(
		private readonly dialogRef: MatDialogRef<IncompleteProfileDialogComponent>,
		private readonly router: Router
	) {
	}

	close() {
		this.dialogRef.close();
	}
}
