import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {EmptyResponseBodyErrorInterceptor} from '@caroo/network/interceptors/empty-response-body-error.interceptor';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {InvalidTokenInterceptor} from './interceptors/invalid-token.interceptor';
import {RequestInterceptor} from './interceptors/request.interceptor';

@NgModule({
	declarations: [],
	imports: [
		HttpClientModule
	],
	exports: [HttpClientModule],
	providers: [{
		provide: HTTP_INTERCEPTORS,
		useClass: EmptyResponseBodyErrorInterceptor,
		multi: true
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		multi: true
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: InvalidTokenInterceptor,
		multi: true
	}, {
		provide: HTTP_INTERCEPTORS,
		useClass: RequestInterceptor,
		multi: true
	}]
})
export class NetworkModule {
}
