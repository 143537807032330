<!--
<ng-container>
	<div class="options">
		<div class="option-wrapper">
			<div class="card option">
				<h3>Small Businesses</h3>
				<div class="option-content">
					<ul>
						<li>One-off setup fee</li>
						<li>Unlimited job ads</li>
						<li>Unlimited hires</li>
						<li>Unlimited users</li>
						<li>3 months unrestricted access</li>
					</ul>
					<label class="price">£175</label>
					<label class="period">Paid quarterly</label>
					<button caroo-primary-button (click)="startSubscription(Plan.SMALL_BUSINESS)">Pay Now</button>
					<p class="subtitle ask-colleague" (click)="sendPaymentDetailsToColleague(Plan.SMALL_BUSINESS)">Or ask a colleague to pay</p>
				</div>
			</div>
		</div>
		<div class="option-wrapper">
			<div class="card option">
				<h3>Medium Businesses</h3>
				<div class="option-content">
					<ul>
						<li>Everything included in <strong>Standard (Quarterly)</strong></li>
						<li>12 months unrestricted access</li>
						<li>Heavily discounted rate</li>
					</ul>
					<label class="price">£167</label>
					<label class="period">Paid yearly</label>
					<button caroo-primary-button (click)="startSubscription(Plan.MEDIUM_BUSINESS)">Pay Now</button>
					<p class="subtitle ask-colleague" (click)="sendPaymentDetailsToColleague(Plan.MEDIUM_BUSINESS)">Or ask a colleague to pay</p>
				</div>
			</div>
		</div>
		<div class="option-wrapper full-service">
			<div class="card option">
				<h3>Large Businesses</h3>
				<div class="option-content">
					<ul>
						<li>Everything included in <strong>Standard (Annual)</strong></li>
						<li>Dedicated account manager</li>
						<li>Administrative support</li>
						<li>Support posting jobs</li>
						<li>Recruitment advice</li>
						<li>Available on invoice</li>
						<li>Co-branded ads</li>
						<li>12 months unrestricted access</li>
					</ul>
					<label class="price">£250</label>
					<label class="period">Paid yearly</label>
					<button caroo-primary-button (click)="startSubscription(Plan.LARGE_BUSINESS)">Pay Now</button>
					<p class="subtitle ask-colleague" (click)="sendPaymentDetailsToColleague(Plan.LARGE_BUSINESS)">Or ask a colleague to pay</p>
				</div>
			</div>
		</div>
	</div>
	&lt;!&ndash;<div class="bottom">
		<div class="enterprise">
			<p>Are you a large employer (500+ head count) with multiple sites? You'll need something more.<br/>Get in touch to discuss. <a href="tel:+447591208175">07591 208175</a></p>
		</div>
		<p class="access" (click)="startSubscription(Plan.MANAGED_SERVICE)">Monthly access £399 (without support)</p>
	</div>&ndash;&gt;
	<mat-icon class="close" (click)="close()">close</mat-icon>
</ng-container>
-->
<div class="card option">
	<h3 class="title">Small Businesses</h3>
	<ul>
		<li>One-off setup fee</li>
		<li>Unlimited job ads</li>
		<li>Unlimited hires</li>
		<li>Unlimited users</li>
		<li>3 months unrestricted access</li>
	</ul>
	<p class="price">£175</p>
	<p class="setup-fee">Setup fee of £450</p>
	<button caroo-primary-button (click)="startSubscription(Plan.SMALL_BUSINESS)">Pay Now</button>
	<p class="invite-colleague" (click)="sendPaymentDetailsToColleague(Plan.SMALL_BUSINESS)">Invite Colleague To Pay</p>
</div>
<div class="card option">
	<h3 class="title">Medium Businesses</h3>
	<ul>
		<li>One-off setup fee</li>
		<li>Unlimited job ads</li>
		<li>Unlimited hires</li>
		<li>Unlimited users</li>
		<li>3 months unrestricted access</li>
	</ul>
	<p class="price">£245</p>
	<p class="setup-fee">Setup fee of £950</p>
	<button caroo-primary-button (click)="startSubscription(Plan.MEDIUM_BUSINESS)">Pay Now</button>
	<p class="invite-colleague" (click)="sendPaymentDetailsToColleague(Plan.MEDIUM_BUSINESS)">Invite Colleague To Pay</p>
</div>
<div class="card option">
	<h3 class="title">Large Businesses</h3>
	<ul>
		<li>One-off setup fee</li>
		<li>Unlimited job ads</li>
		<li>Unlimited hires</li>
		<li>Unlimited users</li>
		<li>3 months unrestricted access</li>
	</ul>
	<p class="price">£345</p>
	<p class="setup-fee">Setup fee of £1,950</p>
	<button caroo-primary-button (click)="startSubscription(Plan.LARGE_BUSINESS)">Pay Now</button>
	<p class="invite-colleague" (click)="sendPaymentDetailsToColleague(Plan.LARGE_BUSINESS)">Invite Colleague To Pay</p>
</div>
<div class="card option managed-service">
	<h3 class="title">Managed Service</h3>
	<div class="left">
		<h4>Only 5% per hire</h4>
		<button caroo-primary-button (click)="startSubscription(Plan.MANAGED_SERVICE)">Start Hiring Now</button>
	</div>
	<ul>
		<li>Full setup and support</li>
		<li>Unlimited job ads</li>
		<li>Accurate candidate matching</li>
		<li>Candidate pipeline tools</li>
		<li>Only £95 per month management fee</li>
		<li>No setup fee</li>
	</ul>
</div>
<div class="book-meeting">
	<p>Speak to a member of our team to get the best deal and to see how Caroo will work for you.</p>
	<p>Call <a href="tel:07951783899">07951 783899</a></p>
	<p>Email <a href="mailto:daniel.morgan@caroo.co.uk">daniel.morgan@caroo.co.uk</a></p>
	<a href="https://meetings.hubspot.com/gareth46/caroodemo" target="_blank">
		<button caroo-primary-button>Book a meeting</button>
	</a>
</div>
<mat-icon class="close" (click)="close()">close</mat-icon>
