import {Colleague} from '@caroo/shared/models';
import {UserProfile} from '@caroo/users/models/user-profile';
import {Action} from '@ngrx/store';

export enum UserProfileActionTypes {
	LoadUserProfile = '[User] Load User Profile',
	SetUserProfile = '[User] Set User Profile',
	UpdateUserProfile = '[User] Update User Profile',
	LoadColleagues = '[User] Load Colleagues',
	SetColleagues = '[User] Set Colleagues'
}

export class LoadUserProfile implements Action {
	readonly type = UserProfileActionTypes.LoadUserProfile;
}

export class SetUserProfile implements Action {
	readonly type = UserProfileActionTypes.SetUserProfile;

	constructor(public payload: { userProfile: UserProfile }) {
	}
}

export class UpdateUserProfile implements Action {
	readonly type = UserProfileActionTypes.UpdateUserProfile;

	constructor(public payload: { name: string, jobTitle?: string }) {
	}
}

export class LoadColleagues implements Action {
	readonly type = UserProfileActionTypes.LoadColleagues;
}

export class SetColleagues implements Action {
	readonly type = UserProfileActionTypes.SetColleagues;

	constructor(public payload: { colleagues: Colleague[] }) {
	}
}

export type UserActions = LoadUserProfile | SetUserProfile | UpdateUserProfile | LoadColleagues | SetColleagues;
