import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(http) {
        this.http = http;
    }
    getUserProfile() {
        return this.http.get(`${environment.baseUrl}/brands/users/profile`);
    }
    updateUserProfile(name, jobTitle) {
        return this.http.patch(`${environment.baseUrl}/brands/users/profile`, { name, jobTitle });
    }
    updateUserProfileAvatar(resource) {
        if (resource && resource.file) {
            const data = new FormData();
            data.append('avatar', resource.file);
            return this.http.put(`${environment.baseUrl}/brands/users/profile/avatar`, data);
        }
        else {
            return of({});
        }
    }
    getColleagues() {
        return this.http.get(`${environment.baseUrl}/brands/users/colleagues`);
    }
    inviteColleagues(emails) {
        return this.http.post(`${environment.baseUrl}/brands/users`, { emails });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
