import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ScoreIndicatorComponent} from './score-indicator.component';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [ScoreIndicatorComponent],
	exports: [ScoreIndicatorComponent]
})
export class ScoreIndicatorModule {
}
