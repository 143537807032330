interface Config {
	apiKey: string;
	authDomain: string;
	databaseURL: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
}

export class DevFirebaseConfig implements Config {
	apiKey = 'AIzaSyAbHVTqdndNVjkvpVVzRIi_J2iBUuN1zxg';
	authDomain = 'caroo-dev.firebaseapp.com';
	databaseURL = 'https://caroo-dev.firebaseio.com';
	projectId = 'caroo-dev';
	storageBucket = 'caroo-dev.appspot.com';
	messagingSenderId = '1014671311381';
	appId = '1:1014671311381:web:a5622ea964a5b8d35dc41c';
}

export class StagingFirebaseConfig implements Config {
	apiKey = 'AIzaSyDwPhjz0DeNTavYP3HKy3RjxAPlNI4BOD8';
	authDomain = 'caroo-staging-714f6.firebaseapp.com';
	databaseURL = 'https://caroo-staging-714f6.firebaseio.com';
	projectId = 'caroo-staging';
	storageBucket = 'caroo-staging.appspot.com';
	messagingSenderId = '438140879835';
	appId = '1:438140879835:web:7ac268f52083107adb5dfe';
}

export class DemoFirebaseConfig implements Config {
	apiKey = 'AIzaSyD_xupivwbDmol_qdqzdzBdiuh19QdR4Aw';
	authDomain = 'caroo-demo.firebaseapp.com';
	databaseURL = 'https://caroo-demo.firebaseio.com';
	projectId = 'caroo-demo';
	storageBucket = 'caroo-demo.appspot.com';
	messagingSenderId = '176919215936';
	appId = '1:176919215936:web:0611f1e88cbe67adbd04a5';
}

export class ProductionFirebaseConfig implements Config {
	apiKey = 'AIzaSyAwvUEDZQf6YSm6nivlV69vZvg0x9OL6tQ';
	authDomain = 'caroo-production.firebaseapp.com';
	databaseURL = 'https://caroo-production.firebaseio.com';
	projectId = 'caroo-production';
	storageBucket = 'caroo-production.appspot.com';
	messagingSenderId = '347934238089';
	appId = '1:347934238089:web:c8b1e0e85b04dfe4c4ad58';
}

export type FirebaseConfig =
	| DevFirebaseConfig
	| StagingFirebaseConfig
	| DemoFirebaseConfig
	| ProductionFirebaseConfig;
