import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CroutonService {
	private source = new BehaviorSubject<Crouton.Message>(null);
	message = this.source.asObservable();

	constructor() {
	}

	sendMessage(message: Crouton.Message) {
		this.source.next(message);
	}
}

export namespace Crouton {
	export enum Type {
		SUCCESS,
		ERROR
	}

	export interface Message {
		type: Type;
		message: string;
	}
}
