import {NgModule} from '@angular/core';
import {CoachmarkDirective} from '@caroo/shared/coachmarks/coachmark.directive';
import {PopoverDirective, PopoverModule} from '@caroo/shared/components/popover';

@NgModule({
	imports: [PopoverModule],
	declarations: [CoachmarkDirective],
	exports: [
		CoachmarkDirective,
		PopoverDirective
	]
})
export class CoachmarkModule {
}
