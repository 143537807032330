import { MediaMatcher } from '@angular/cdk/layout';
import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
export class MobileQueryService {
    constructor(media) {
        this.isMobile = false;
        this.isMobileSubject = new BehaviorSubject(false);
        this.isMobile$ = this.isMobileSubject.asObservable();
        this.isNotMobile$ = this.isMobile$.pipe(map(isMobile => !isMobile));
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = event => {
            this.isMobile = event.matches;
            this.isMobileSubject.next(this.isMobile);
        };
        this.mobileQuery.addListener(this.mobileQueryListener);
        this.isMobile = this.mobileQuery.matches;
        this.isMobileSubject.next(this.isMobile);
    }
    ngOnDestroy() {
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }
}
MobileQueryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileQueryService_Factory() { return new MobileQueryService(i0.ɵɵinject(i1.MediaMatcher)); }, token: MobileQueryService, providedIn: "root" });
