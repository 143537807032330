import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material';
import {PaymentStateModule} from '@caroo/payment/payment-state.module';
import {PaymentService} from '@caroo/payment/payment.service';
import {SubscriptionStartedDialogModule} from '@caroo/payment/subscription-started-dialog/subscription-started-dialog.module';
import {InfoDialogModule} from '@caroo/shared/info-dialog';

@NgModule({
	imports: [
		InfoDialogModule,
		MatDialogModule,
		PaymentStateModule,
		SubscriptionStartedDialogModule
	],
	providers: [PaymentService]
})
export class PaymentModule {
}
