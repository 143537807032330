import {EmployeeNotificationFragment, ResourceFragment} from '../../../generated/graphql';

export function getAvatarForNotification(notification: EmployeeNotificationFragment): ResourceFragment | null {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			return null;
		case 'NewInterestedCandidateEmployeeNotification':
			return notification.candidate.avatar;
		case 'NewMatchedCandidateEmployeeNotification':
			return notification.candidate.avatar;
		case 'NewMessageEmployeeNotification':
			return notification.message.participant.avatar;
	}
}

export function getPlainTitleForNotification(notification: EmployeeNotificationFragment): string {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			return 'Someone has commented on your content!';
		case 'NewInterestedCandidateEmployeeNotification':
			return `${notification.candidate.name} is interested`;
		case 'NewMatchedCandidateEmployeeNotification':
			return `It's a match! You and ${notification.candidate.name} are interested. Start chatting now!`;
		case 'NewMessageEmployeeNotification':
			return `New message from ${notification.message.participant.name}`;
	}
}

export function getStyledTitleForNotification(notification: EmployeeNotificationFragment): string {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			return 'Someone has commented on your content!';
		case 'NewInterestedCandidateEmployeeNotification':
			return `${notification.candidate.name} is <strong>interested</strong>`;
		case 'NewMatchedCandidateEmployeeNotification':
			return `<strong>It's a match!</strong> You and ${notification.candidate.name} are interested. Start chatting now!`;
		case 'NewMessageEmployeeNotification':
			return `<strong>New message</strong> from ${notification.message.participant.name}`;
	}
}

export function getContentForNotification(notification: EmployeeNotificationFragment): string {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			switch (notification.comment.content.__typename) {
				case 'Article':
					return notification.comment.content.title;
				case 'Post':
					return notification.comment.content.text;
				default:
					return '';
			}
		case 'NewInterestedCandidateEmployeeNotification':
			return notification.role.title;
		case 'NewMatchedCandidateEmployeeNotification':
			return notification.role.title;
		case 'NewMessageEmployeeNotification':
			return notification.message.conversation.role.title;
	}
}

export function getIconForNotification(notification: EmployeeNotificationFragment): 'interested' | 'matches' | 'messages' {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			return 'messages';
		case 'NewInterestedCandidateEmployeeNotification':
			return 'interested';
		case 'NewMatchedCandidateEmployeeNotification':
			return 'matches';
		case 'NewMessageEmployeeNotification':
			return 'messages';
	}
}

export function getLinkForNotification(notification: EmployeeNotificationFragment): string {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			return `/content-hub/${notification.comment.content.id}`;
		case 'NewInterestedCandidateEmployeeNotification':
			return `/roles/${notification.role.id}/candidates/${notification.candidate.id}`;
		case 'NewMatchedCandidateEmployeeNotification':
			return `/roles/${notification.role.id}/candidates/${notification.candidate.id}`;
		case 'NewMessageEmployeeNotification':
			return `/messages/${notification.message.conversation.id}`;
	}
}


export function getScoreForNotification(notification: EmployeeNotificationFragment): number | null {
	switch (notification.__typename) {
		case 'NewContentCommentEmployeeNotification':
			return null;
		case 'NewInterestedCandidateEmployeeNotification':
			return Math.round(notification.score);
		case 'NewMatchedCandidateEmployeeNotification':
			return Math.round(notification.score);
		case 'NewMessageEmployeeNotification':
			return null;
	}
}
