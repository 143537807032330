import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {QueryRef, SubscriptionResult} from 'apollo-angular';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GetNotificationsGQL, GetNotificationsQuery, GetNotificationsQueryVariables, NotificationReceivedGQL, NotificationReceivedSubscription} from '../../../generated/graphql';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	constructor(
		private readonly getNotificationsGQL: GetNotificationsGQL,
		private readonly notificationReceivedGQL: NotificationReceivedGQL,
		private readonly http: HttpClient
	) {
	}

	getNotifications(): QueryRef<GetNotificationsQuery, GetNotificationsQueryVariables> {
		return this.getNotificationsGQL.watch(undefined, {fetchPolicy: 'cache-and-network'});
	}

	subscribeToNewNotifications(): Observable<SubscriptionResult<NotificationReceivedSubscription>> {
		return this.notificationReceivedGQL.subscribe();
	}

	markAllNotificationsAsRead(): Observable<any> {
		return this.http.put<any>(`${environment.baseUrl}/notifications/all/read`, undefined);
	}

	getNumUnreadNotifications(): Observable<{ num: number }> {
		return this.http.get<{ num: number }>(`${environment.baseUrl}/notifications/unread/count`);
	}

	markNotificationAsRead(id: number): Promise<any> {
		return this.http.post<any>(`${environment.baseUrl}/notifications/${id}/read`, null).toPromise();
	}
}
