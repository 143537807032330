/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lottie/lottie.component.ngfactory";
import * as i3 from "../lottie/lottie.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../button/button.component.ngfactory";
import * as i7 from "../button/button.component";
import * as i8 from "./info-dialog.component";
import * as i9 from "@angular/material/dialog";
var styles_InfoDialogComponent = [i0.styles];
var RenderType_InfoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoDialogComponent, data: {} });
export { RenderType_InfoDialogComponent as RenderType_InfoDialogComponent };
function View_InfoDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-lottie", [], null, null, null, i2.View_LottieComponent_0, i2.RenderType_LottieComponent)), i1.ɵdid(1, 245760, null, 0, i3.LottieComponent, [], { options: [0, "options"], width: [1, "width"], height: [2, "height"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lottieOptions; var currVal_1 = 160; var currVal_2 = 160; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_InfoDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i7.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.action.route; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.data.action.text; _ck(_v, 4, 0, currVal_3); }); }
function View_InfoDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InfoDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_6)), i1.ɵdid(3, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.route; _ck(_v, 1, 0, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 4); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_InfoDialogComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InfoDialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_8)), i1.ɵdid(1, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 4); _ck(_v, 1, 0, currVal_0); }, null); }
function View_InfoDialogComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i7.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.text; _ck(_v, 2, 0, currVal_0); }); }
function View_InfoDialogComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["caroo-secondary-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i7.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.text; _ck(_v, 2, 0, currVal_0); }); }
function View_InfoDialogComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_10)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_11)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.type == "primary"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.type == "secondary"); _ck(_v, 3, 0, currVal_1); }, null); }
function View_InfoDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_5)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLink", 2]], null, 0, null, View_InfoDialogComponent_7)), (_l()(), i1.ɵand(0, [["actions", 2]], null, 0, null, View_InfoDialogComponent_9))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.route; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InfoDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_4)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.actions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "body1"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoDialogComponent_3)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lottieOptions; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.data.action; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.data.actions; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data.body; _ck(_v, 6, 0, currVal_2); }); }
export function View_InfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-info-dialog", [], null, null, null, View_InfoDialogComponent_0, RenderType_InfoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.InfoDialogComponent, [i9.MAT_DIALOG_DATA, i9.MatDialogRef], null, null)], null, null); }
var InfoDialogComponentNgFactory = i1.ɵccf("caroo-info-dialog", i8.InfoDialogComponent, View_InfoDialogComponent_Host_0, {}, {}, []);
export { InfoDialogComponentNgFactory as InfoDialogComponentNgFactory };
