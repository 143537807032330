import {Component, Input} from '@angular/core';
import {Resource} from '@caroo/shared/models';
import {ResourceFragment} from '../../../generated/graphql';

@Component({
	selector: 'caroo-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
	@Input()
	resource: Resource | ResourceFragment;
	fallback: Resource = {
		type: Resource.Type.IMAGE,
		resource: 'assets/images/anonymous-brand.svg'
	};
}
