import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NumCandidatesComponent} from '@caroo/dashboard/components/num-candidates/num-candidates.component';
import {DashboardStateModule} from '@caroo/dashboard/dashboard-state.module';

@NgModule({
	declarations: [NumCandidatesComponent],
	imports: [
		CommonModule,
		DashboardStateModule
	],
	exports: [
		NumCandidatesComponent
	]
})
export class NumCandidatesModule {
}
