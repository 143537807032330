import {Component, ElementRef, Input} from '@angular/core';

@Component({
	// tslint:disable-next-line:component-selector
	selector: `
	button[caroo-primary-button],
	button[caroo-secondary-button],
	button[caroo-tertiary-button],
	button[caroo-add-role-button],
	button[caroo-interested-button],
	button[caroo-not-interested-button],
	button[caroo-message-button],
	button[caroo-danger-button],
	button[caroo-dark-button]
	`,
	exportAs: 'button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
	@Input()
	rippleColour = '';

	constructor(private elementRef: ElementRef) {
		const e = this.elementRef.nativeElement as HTMLElement;
		if (e.hasAttribute('caroo-interested-button')) {
			this.rippleColour = 'rgba(59,150,255,0.3)';
		} else if (e.hasAttribute('caroo-not-interested-button')) {
			this.rippleColour = 'rgba(252,41,100,0.3)';
		}
	}
}
