var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptBrandInviteGQL, USE_BRAND_API } from '@caroo/graphql-new';
import { InfoDialogComponent } from '@caroo/shared/info-dialog';
import { first } from 'rxjs/operators';
export class AcceptInviteComponent {
    constructor(acceptBrandInviteGQL, activatedRoute, angularFireAuth, formBuilder, matDialog, router) {
        this.acceptBrandInviteGQL = acceptBrandInviteGQL;
        this.activatedRoute = activatedRoute;
        this.angularFireAuth = angularFireAuth;
        this.formBuilder = formBuilder;
        this.matDialog = matDialog;
        this.router = router;
        this.lottieConfig = {
            path: 'assets/animations/logo.json',
            renderer: 'canvas',
            autoplay: true,
            loop: true
        };
        this.email = new FormControl('', [Validators.required, Validators.email]);
        this.password = new FormControl('', [Validators.required, Validators.minLength(6)]);
        this.formGroup = this.formBuilder.group({
            email: this.email,
            password: this.password,
        });
        this.hide = true;
    }
    ngOnInit() {
        this.activatedRoute.queryParamMap.pipe(first()).subscribe((paramMap) => {
            this.token = paramMap.get('token');
            this.email.setValue(decodeURIComponent(paramMap.get('email')));
        });
    }
    onSubmit() {
        return __awaiter(this, void 0, void 0, function* () {
            let userCredential;
            try {
                userCredential = yield this.angularFireAuth.auth.createUserWithEmailAndPassword(this.email.value, this.password.value);
                yield userCredential.user.getIdToken(true);
            }
            catch (e) {
                console.error(e);
                if (userCredential) {
                    yield userCredential.user.delete();
                }
                this.matDialog.open(InfoDialogComponent, {
                    data: {
                        title: 'Error',
                        body: `Something went wrong (${e}); please contact Caroo for assistance`,
                        action: {
                            text: 'Try again'
                        }
                    }
                });
                return;
            }
            try {
                yield this.acceptBrandInviteGQL.mutate({ input: { token: this.token } }, USE_BRAND_API).toPromise();
                yield userCredential.user.getIdToken(true);
            }
            catch (e) {
                console.error(e);
                this.matDialog.open(InfoDialogComponent, {
                    data: {
                        title: 'Error',
                        body: 'This invite link does not appear to be valid. Please ask the colleague that invited you to do so again',
                        action: {
                            text: 'Try again'
                        }
                    }
                });
                yield userCredential.user.delete();
                return;
            }
            yield this.router.navigateByUrl('');
        });
    }
}
