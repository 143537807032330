import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {NotificationComponent} from '@caroo/notifications/components/notification/notification.component';
import {AvatarModule} from '@caroo/shared/avatar';
import {ScoreIndicatorModule} from '@caroo/shared/score-indicator';
import {TimeAgoModule} from '@caroo/shared/time-ago';

@NgModule({
	declarations: [NotificationComponent],
	imports: [
		AvatarModule,
		CommonModule,
		MatRippleModule,
		MatIconModule,
		RouterModule,
		ScoreIndicatorModule,
		TimeAgoModule
	],
	exports: [NotificationComponent]
})
export class NotificationModule {
}
