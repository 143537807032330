import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { PopoverComponent } from './popover.component';
import { PopoverRef } from './popover.ref';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
export class PopoverService {
    constructor(overlay, injector) {
        this.overlay = overlay;
        this.injector = injector;
    }
    static createInjector(popoverRef, injector) {
        const tokens = new WeakMap([[PopoverRef, popoverRef]]);
        return new PortalInjector(injector, tokens);
    }
    open({ origin, content, data, width, height, hasBackdrop, arrowPosition, positions }) {
        const overlayRef = this.overlay.create(this.getOverlayConfig(origin, width, height, hasBackdrop, positions));
        const popoverRef = new PopoverRef(overlayRef, content, data, arrowPosition);
        const injector = PopoverService.createInjector(popoverRef, this.injector);
        overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
        return popoverRef;
    }
    getOverlayPosition(origin, positions) {
        return this.overlay.position()
            .flexibleConnectedTo(origin)
            .withPositions(positions || []);
    }
    getOverlayConfig(origin, width, height, hasBackdrop, positions) {
        return new OverlayConfig({
            width,
            height,
            hasBackdrop: hasBackdrop,
            backdropClass: 'popover-backdrop',
            positionStrategy: this.getOverlayPosition(origin, positions),
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });
    }
}
PopoverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopoverService_Factory() { return new PopoverService(i0.ɵɵinject(i1.Overlay), i0.ɵɵinject(i0.INJECTOR)); }, token: PopoverService, providedIn: "root" });
