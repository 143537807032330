<ng-container>
	<h1 class="heading">Invite Colleagues</h1>
	<p class="body1">Enter your colleagues' emails to allow them to upload roles and review candidates</p>
	<label class="body2">Enter your colleague's email</label>
	<form [formGroup]="formGroup" autocomplete="off" class="emails" (submit)="sendInvites()">
		<ng-container *ngFor="let entry of controls; let i = index; trackBy: trackFormControl">
			<caroo-form-field>
				<input type="email" name="email{{i}}" carooInput [formControl]="entry[1]"/>
				<ng-container [ngSwitch]="getFormControlStatus(entry[0])">
					<ng-container *ngSwitchCase="undefined">
						<mat-icon svgIcon="remove" *ngIf="!submitting && i > 0" (click)="removeFormControl(entry[0])"></mat-icon>
					</ng-container>
					<mat-progress-spinner *ngSwitchCase="'pending'" color="primary" diameter="20"
					                      mode="indeterminate"></mat-progress-spinner>
					<mat-icon *ngSwitchCase="'success'" svgIcon="tick"></mat-icon>
					<mat-icon *ngSwitchCase="'failure'" svgIcon="error"></mat-icon>
				</ng-container>
			</caroo-form-field>
			<label *ngIf="apiErrors[entry[0]]" class="body2 error">{{apiErrors[entry[0]]}}</label>
		</ng-container>
		<label *ngIf="!submitting" class="body1 add" (click)="addFormControl()">+ Add More</label>
		<button type="submit" caroo-primary-button [disabled]="formGroup.invalid || submitting">Invite</button>
	</form>
</ng-container>
