import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import enGbLocale from '@angular/common/locales/en-GB';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AcceptInviteModule} from '@caroo/auth/components/accept-invite';
import {ConfigService} from '@caroo/config/config.service';
import {NumCandidatesModule} from '@caroo/dashboard/components/num-candidates/num-candidates.module';
import {PaymentInfoDialogModule} from '@caroo/payment/payment-info-dialog/payment-info-dialog.module';
import {PaymentStateModule} from '@caroo/payment/payment-state.module';
import {PaymentModule} from '@caroo/payment/payment.module';
import {ProfileStateModule} from '@caroo/profile/profile-state.module';
import {IconRegistryService} from '@caroo/services/icon-registry.service';
import {AvatarModule} from '@caroo/shared/avatar';
import {CoachmarkModule} from '@caroo/shared/coachmarks/coachmark.module';
import {PopoverModule} from '@caroo/shared/components/popover';
import {AuthenticationGuard} from '@caroo/shared/guards';
import {IncompleteProfileDialogModule} from '@caroo/shared/incomplete-profile-dialog';
import {InviteColleaguesDialogModule} from '@caroo/shared/invite-colleagues-dialog';
import {LogoModule} from '@caroo/shared/logo';
import {LottieModule} from '@caroo/shared/lottie';
import {StackdriverErrorHandler} from '@caroo/stackdriver.error-handler';
import {clearState} from '@caroo/store/reducers';
import {UserStateModule} from '@caroo/users/user-state.module';
import {EffectsModule} from '@ngrx/effects';
import {routerReducer, RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';
import {TextareaResizeDirective} from './directives/textarea-resize.directive';
import {GraphQLModule} from './graphql.module';
import {LoadingService} from './network/loading.service';
import {NetworkModule} from './network/network.module';
import {NotificationModule} from './notifications/notification.module';
import {CroutonService} from './services/crouton.service';
import {ElectronService} from './services/electron.service';
import {ButtonModule} from './shared/button/button.module';

registerLocaleData(enGbLocale);

@NgModule({
	declarations: [
		AppComponent,
		TextareaResizeDirective
	],
	imports: [
		AcceptInviteModule,
		AngularFireModule,
		AngularFireAuthModule,
		AvatarModule,
		BrowserModule,
		BrowserAnimationsModule,
		ButtonModule,
		CoachmarkModule,
		GraphQLModule,
		HttpClientModule,
		IncompleteProfileDialogModule,
		InviteColleaguesDialogModule,
		LogoModule,
		LottieModule.forRoot(),
		MatDialogModule,
		NetworkModule,
		NgCircleProgressModule.forRoot({
			radius: 32,
			space: -4,
			outerStrokeWidth: 4,
			outerStrokeColor: '#FFFFFF',
			outerStrokeLinecap: 'butt',
			innerStrokeWidth: 4,
			innerStrokeColor: '#FFFFFF33',
			showSubtitle: false,
			showBackground: false,
			titleColor: '#FFFFFF',
			unitsColor: '#FFFFFF'
		}),
		MatButtonModule,
		MatIconModule,
		MatProgressBarModule,
		MatRippleModule,
		MatSidenavModule,
		MatToolbarModule,
		PaymentInfoDialogModule,
		PaymentModule,
		PaymentStateModule,
		PerfectScrollbarModule,
		PopoverModule,
		ProfileStateModule,
		RouterModule.forRoot([
			{
				path: 'register',
				loadChildren: () => import('./auth/components/registration/registration.module').then(m => m.RegistrationModule)
			},
			{
				path: 'login',
				loadChildren: () => import('./auth/components/login/login.module').then(m => m.LoginModule)
			},
			{
				path: 'admin-login',
				loadChildren: () => import('./auth/components/admin-login/admin-login.module').then(m => m.AdminLoginModule)
			},
			{
				path: 'forgot-password',
				loadChildren: () => import('./auth/components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
			},
			{
				path: 'reset-password',
				loadChildren: () => import('./auth/components/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
			},
			{
				path: 'auth-action',
				loadChildren: () => import('./auth/components/auth-action/auth-action.module').then(m => m.AuthActionModule)
			},
			{
				path: '',
				loadChildren: () => import('./dashboard/components/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'profile',
				loadChildren: () => import('./profile/profile-routing.module').then(m => m.ProfileRoutingModule)
			},
			{
				path: 'messages',
				loadChildren: () => import('./messages/message.module').then(m => m.MessageModule)
			},
			{
				path: 'roles',
				loadChildren: () => import('./roles/role.module').then(m => m.RoleModule)
			},
			{
				path: 'roles/:roleId/candidates',
				loadChildren: () => import('./candidates/candidate.module').then(m => m.CandidateModule)
			},
			{
				path: 'pipeline',
				loadChildren: () => import('./pipeline/pipeline-routing.module').then(m => m.PipelineRoutingModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'content-hub',
				loadChildren: () => import('./content-hub/content-hub-routing.module').then(m => m.ContentHubRoutingModule),
				canActivate: [AuthenticationGuard]
			},
			{
				path: 'external-payment',
				loadChildren: () => import('./payment/external-payment/external-payment.routing-module').then(m => m.ExternalPaymentRoutingModule),
			},
			{
				path: '**',
				redirectTo: '/'
			}
		], {useHash: false}),
		NotificationModule,
		UserStateModule,
		StoreModule.forRoot({
			router: routerReducer,
		}, {
			metaReducers: [clearState],
			runtimeChecks: {
				strictActionImmutability: true,
				strictActionSerializability: true,
				strictStateImmutability: true,
				strictStateSerializability: true
			}
		}),
		StoreDevtoolsModule.instrument(),
		EffectsModule.forRoot([]),
		// SharedModule,
		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal
		}),
		NumCandidatesModule
	],
	providers: [ConfigService, {
		provide: APP_INITIALIZER,
		useFactory: (configService: ConfigService) => () => configService.initEnvironment(),
		deps: [ConfigService],
		multi: true
	}, CroutonService, ElectronService, {
		provide: PERFECT_SCROLLBAR_CONFIG,
		useValue: <PerfectScrollbarConfigInterface>{
			suppressScrollX: true
		}
	}, IconRegistryService, LoadingService, {
		provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
		useValue: {
			duration: 2000
		}
	}, {
		provide: LOCALE_ID,
		useValue: 'en-GB'
	}, {
		provide: ErrorHandler,
		useClass: StackdriverErrorHandler,
		deps: [ConfigService]
	}],
	exports: [
		TextareaResizeDirective
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
