<ng-container>
	<div class="top">
		<img src="/assets/images/logo.svg" alt="Logo"/>
		<h1 class="title">Looks like you have a few things missing...</h1>
	</div>
	<p class="body1">Before you can <span class="add-role">add a role</span> you must complete the following sections on your profile:</p>
	<div class="middle">
		<label>1</label>
		<label>Tell us about you</label>
		<label>2</label>
		<label>Let's see who you are</label>
	</div>
	<div class="bottom">
		<label class="body1" (click)="close()">Cancel</label>
		<a routerLink="/profile/edit">
			<button caroo-primary-button (click)="close()">Go to profile</button>
		</a>
	</div>
</ng-container>
