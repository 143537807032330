import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	constructor(private readonly http: HttpClient) {
	}
}
