import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PaymentInfoButtonDirective } from '@caroo/payment/buttons/payment-info-button.directive';
import { SubscriptionButtonDirective } from '@caroo/payment/buttons/subscription-button.directive';

@NgModule({
	imports: [
		MatDialogModule,
	],
	declarations: [
		PaymentInfoButtonDirective,
		SubscriptionButtonDirective
	],
	exports: [
		PaymentInfoButtonDirective,
		SubscriptionButtonDirective
	]
})
export class PaymentButtonModule {
}
