
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Content",
        "possibleTypes": [
          {
            "name": "Article"
          },
          {
            "name": "Post"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ConversationParticipant",
        "possibleTypes": [
          {
            "name": "Employee"
          },
          {
            "name": "Candidate"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "EmployeeNotification",
        "possibleTypes": [
          {
            "name": "NewContentCommentEmployeeNotification"
          },
          {
            "name": "NewInterestedCandidateEmployeeNotification"
          },
          {
            "name": "NewMatchedCandidateEmployeeNotification"
          },
          {
            "name": "NewMessageEmployeeNotification"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Commenter",
        "possibleTypes": [
          {
            "name": "AnonymousCommenter"
          },
          {
            "name": "NamedCommenter"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Liker",
        "possibleTypes": [
          {
            "name": "AnonymousLiker"
          },
          {
            "name": "NamedLiker"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ContentActivity",
        "possibleTypes": [
          {
            "name": "Comment"
          },
          {
            "name": "Like"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ArticleSection",
        "possibleTypes": [
          {
            "name": "ResourceArticleSection"
          },
          {
            "name": "TextArticleSection"
          }
        ]
      }
    ]
  }
};
      export default result;
    