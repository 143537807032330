import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ScrollComponent} from '@caroo/shared/scroll/scroll.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

@NgModule({
	declarations: [ScrollComponent],
	imports: [
		CommonModule,
		PerfectScrollbarModule
	],
	exports: [ScrollComponent]
})
export class ScrollModule {
}
