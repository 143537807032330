var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorage } from '../../storage/decorator';
export class AuthInterceptor {
    constructor(angularFireAuth, httpClient) {
        this.angularFireAuth = angularFireAuth;
        this.httpClient = httpClient;
    }
    exchangeLegacyToken(legacyToken) {
        return this.httpClient
            .get(`${environment.baseUrl}/auth/firebase-auth-custom-token`, {
            headers: {
                Authorization: `Bearer ${legacyToken}`
            }
        })
            .pipe(switchMap(({ token }) => from(this.angularFireAuth.auth.signInWithCustomToken(token))), switchMap(credential => from(credential.user.getIdToken())));
    }
    intercept(req, next) {
        if (!req.url.includes(environment.baseUrl) ||
            req.url.includes('shortlists') ||
            req.url.includes('companieshouse') ||
            req.url.includes('/brands/users/accept') ||
            req.url.includes('redirect-auth-action')) {
            return next.handle(req);
        }
        let observable;
        if (req.url.includes('firebase-auth-custom-token')) {
            observable = of(this.token);
        }
        else {
            observable = this.angularFireAuth.user.pipe(first(), switchMap(user => {
                if (!!user) {
                    return from(user.getIdToken());
                }
                else {
                    return this.exchangeLegacyToken(this.token);
                }
            }));
        }
        return observable.pipe(switchMap(idToken => {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${idToken}`
                }
            });
            return next.handle(req);
        }));
    }
}
__decorate([
    LocalStorage,
    __metadata("design:type", String)
], AuthInterceptor.prototype, "token", void 0);
