import {TextFieldModule} from '@angular/cdk/text-field';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule, MatIconModule} from '@angular/material';
import {PaymentDetailsSentToColleagueDialogModule} from '@caroo/payment/payment-details-sent-to-colleague-dialog';
import {PaymentModule} from '@caroo/payment/payment.module';
import {ButtonModule} from '@caroo/shared/button/button.module';
import {CarooFormsModule} from '@caroo/shared/forms';
import {SendPaymentDetailsToColleagueDialogComponent} from './send-payment-details-to-colleague-dialog.component';

@NgModule({
	imports: [
		ButtonModule,
		CarooFormsModule,
		MatDialogModule,
		MatIconModule,
		PaymentDetailsSentToColleagueDialogModule,
		PaymentModule,
		ReactiveFormsModule,
		TextFieldModule
	],
	declarations: [SendPaymentDetailsToColleagueDialogComponent],
	entryComponents: [SendPaymentDetailsToColleagueDialogComponent],
	exports: [SendPaymentDetailsToColleagueDialogComponent]
})
export class SendPaymentDetailsToColleagueDialogModule {
}
