import { registerLocaleData } from '@angular/common';
import enGbLocale from '@angular/common/locales/en-GB';
import { ConfigService } from '@caroo/config/config.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
registerLocaleData(enGbLocale);
const ɵ0 = () => import("./auth/components/registration/registration.module.ngfactory").then(m => m.RegistrationModuleNgFactory), ɵ1 = () => import("./auth/components/login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ2 = () => import("./auth/components/admin-login/admin-login.module.ngfactory").then(m => m.AdminLoginModuleNgFactory), ɵ3 = () => import("./auth/components/forgot-password/forgot-password.module.ngfactory").then(m => m.ForgotPasswordModuleNgFactory), ɵ4 = () => import("./auth/components/reset-password/reset-password.module.ngfactory").then(m => m.ResetPasswordModuleNgFactory), ɵ5 = () => import("./auth/components/auth-action/auth-action.module.ngfactory").then(m => m.AuthActionModuleNgFactory), ɵ6 = () => import("./dashboard/components/dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ7 = () => import("./profile/profile-routing.module.ngfactory").then(m => m.ProfileRoutingModuleNgFactory), ɵ8 = () => import("./messages/message.module.ngfactory").then(m => m.MessageModuleNgFactory), ɵ9 = () => import("./roles/role.module.ngfactory").then(m => m.RoleModuleNgFactory), ɵ10 = () => import("./candidates/candidate.module.ngfactory").then(m => m.CandidateModuleNgFactory), ɵ11 = () => import("./pipeline/pipeline-routing.module.ngfactory").then(m => m.PipelineRoutingModuleNgFactory), ɵ12 = () => import("./content-hub/content-hub-routing.module.ngfactory").then(m => m.ContentHubRoutingModuleNgFactory), ɵ13 = () => import("./payment/external-payment/external-payment.routing-module.ngfactory").then(m => m.ExternalPaymentRoutingModuleNgFactory), ɵ14 = (configService) => () => configService.initEnvironment(), ɵ15 = {
    suppressScrollX: true
}, ɵ16 = {
    duration: 2000
};
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
