import {PaymentActions, PaymentActionTypes} from '@caroo/payment/actions/payment.actions';
import {SubscriptionState} from '../../../generated/graphql';

export interface PaymentState {
	subscriptionState?: SubscriptionState;
}

export const initialState: PaymentState = {};

export function reducer(state = initialState, action: PaymentActions): PaymentState {
	switch (action.type) {
		case PaymentActionTypes.SetBrandSubscriptionState:
			return {
				...state,
				subscriptionState: action.payload.subscriptionState
			};
		default:
			return state;
	}
}
