var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { LoadUserProfile, SetColleagues, SetUserProfile, UserProfileActionTypes } from '@caroo/users/actions/user.actions';
import { UserService } from '@caroo/users/services/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, throttleTime } from 'rxjs/operators';
export class UserEffects {
    constructor(actions$, userService) {
        this.actions$ = actions$;
        this.userService = userService;
        this.loadUserProfile$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadUserProfile))
            .pipe(throttleTime(1000))
            .pipe(switchMap(() => this.userService.getUserProfile()))
            .pipe(map(userProfile => new SetUserProfile({ userProfile })));
        this.updateUserProfile$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.UpdateUserProfile))
            .pipe(throttleTime(1000))
            .pipe(switchMap(({ payload }) => this.userService.updateUserProfile(payload.name, payload.jobTitle)))
            .pipe(map(() => new LoadUserProfile()));
        this.loadColleagues$ = this.actions$
            .pipe(ofType(UserProfileActionTypes.LoadColleagues))
            .pipe(throttleTime(1000))
            .pipe(switchMap(() => this.userService.getColleagues()))
            .pipe(map(colleagues => new SetColleagues({ colleagues })));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "loadUserProfile$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "updateUserProfile$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "loadColleagues$", void 0);
