/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./incomplete-profile-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../button/button.component.ngfactory";
import * as i5 from "../button/button.component";
import * as i6 from "./incomplete-profile-dialog.component";
import * as i7 from "@angular/material/dialog";
var styles_IncompleteProfileDialogComponent = [i0.styles];
var RenderType_IncompleteProfileDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncompleteProfileDialogComponent, data: {} });
export { RenderType_IncompleteProfileDialogComponent as RenderType_IncompleteProfileDialogComponent };
export function View_IncompleteProfileDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "/assets/images/logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Looks like you have a few things missing..."])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "p", [["class", "body1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Before you can "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "add-role"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add a role"])), (_l()(), i1.ɵted(-1, null, [" you must complete the following sections on your profile:"])), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "middle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tell us about you"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Let's see who you are"])), (_l()(), i1.ɵeld(19, 0, null, null, 7, "div", [["class", "bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "label", [["class", "body1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(22, 0, null, null, 4, "a", [["routerLink", "/profile/edit"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i1.ɵdid(25, 49152, null, 0, i5.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Go to profile"]))], function (_ck, _v) { var currVal_2 = "/profile/edit"; _ck(_v, 23, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 23).target; var currVal_1 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_0, currVal_1); }); }
export function View_IncompleteProfileDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-incomplete-profile-dialog", [], null, null, null, View_IncompleteProfileDialogComponent_0, RenderType_IncompleteProfileDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.IncompleteProfileDialogComponent, [i7.MatDialogRef, i2.Router], null, null)], null, null); }
var IncompleteProfileDialogComponentNgFactory = i1.ɵccf("caroo-incomplete-profile-dialog", i6.IncompleteProfileDialogComponent, View_IncompleteProfileDialogComponent_Host_0, {}, {}, []);
export { IncompleteProfileDialogComponentNgFactory as IncompleteProfileDialogComponentNgFactory };
