import {ErrorHandler} from '@angular/core';
import {ConfigService} from '@caroo/config/config.service';
import * as StackdriverErrorReporter from 'stackdriver-errors-js';
import {version} from '../../package.json';

interface Reporter {
	start(options: {
		key: string;
		projectId: string;
		service?: string;
		version?: string;
	});

	report(error: any);
}

export class StackdriverErrorHandler implements ErrorHandler {
	private readonly reporter?: Reporter | undefined;

	constructor(configService: ConfigService) {
		if (configService.environment !== 'development') {
			this.reporter = new StackdriverErrorReporter();
			this.reporter.start({
				key: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
				projectId: 'caroo-217513',
				service: window.location.host,
				version
			});
		}
	}

	handleError(error: any) {
		if (this.reporter) {
			this.reporter.report(error.stack);
		}
	}
}
