import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfigService} from '@caroo/config/config.service';
import {PaymentState} from '@caroo/payment/reducers/payment.reducer';
import {SubscriptionStartedDialogComponent} from '@caroo/payment/subscription-started-dialog/subscription-started-dialog.component';
import {extractData} from '@caroo/util/graphql';
import {Store} from '@ngrx/store';
import {SubscriptionResult} from 'apollo-angular';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {
	ChargebeeHostedPageFragment,
	ChargebeePortalSessionFragment,
	CreateChargebeeHostedPageForExternalFinancierGQL,
	CreateChargebeePortalSessionForExternalFinancierGQL,
	CreateHostedPageGQL,
	CreateOfflineSubscriptionGQL,
	CreatePortalSessionGQL,
	GetBrandSubscriptionStateGQL,
	HostedPageType,
	NotifyEmployeeOfExternalPaymentGQL,
	Plan,
	ReactivateOfflineSubscriptionGQL,
	RequestExternalPaymentGQL,
	RequestExternalPaymentInput,
	SubscriptionState,
	SubscriptionStateChangedGQL,
	SubscriptionStateChangedSubscription
} from '../../generated/graphql';

declare const Chargebee: any;

@Injectable({
	providedIn: 'root'
})
export class PaymentService {
	private chargebee: any;
	private newCheckoutSuccess = false;

	constructor(
		private readonly configService: ConfigService,
		private readonly createHostedPageGql: CreateHostedPageGQL,
		private readonly createOfflineSubscriptionGQL: CreateOfflineSubscriptionGQL,
		private readonly createPortalSessionGql: CreatePortalSessionGQL,
		private readonly getBrandSubscriptionStateGql: GetBrandSubscriptionStateGQL,
		private readonly matDialog: MatDialog,
		private readonly ngZone: NgZone,
		private readonly subscriptionStateChangedGQL: SubscriptionStateChangedGQL,
		private readonly paymentStore: Store<PaymentState>,
		private readonly reactivateOfflineSubscriptionGQL: ReactivateOfflineSubscriptionGQL,
		private readonly requestExternalPaymentGQL: RequestExternalPaymentGQL,
		private readonly createChargebeeHostedPageForExternalFinancierGQL: CreateChargebeeHostedPageForExternalFinancierGQL,
		private readonly createChargebeePortalSessionForExternalFinancierGQL: CreateChargebeePortalSessionForExternalFinancierGQL,
		private readonly notifyEmployeeOfExternalPaymentGQL: NotifyEmployeeOfExternalPaymentGQL
	) {
	}

	initChargebee(): void {
		Chargebee.init({
			site: this.configService.chargebeeSite
		});
		this.chargebee = Chargebee.getInstance();
	}

	private async createHostedPage(type: HostedPageType, plan?: Plan): Promise<ChargebeeHostedPageFragment> {
		return this.createHostedPageGql
			.mutate({type, plan})
			.pipe(first())
			.pipe(extractData('createChargebeeHostedPage'))
			.toPromise();
	}

	checkoutNewSubscription(plan: Plan) {
		this.newCheckoutSuccess = false;
		this.chargebee.openCheckout({
			hostedPage: () => this.createHostedPage(HostedPageType.CHECKOUT_NEW, plan),
			success: () => this.newCheckoutSuccess = true,
			close: () => {
				if (this.newCheckoutSuccess === true) {
					this.ngZone.run(() => this.matDialog.open(SubscriptionStartedDialogComponent));
				}
			}
		});
	}

	private async createHostedPageForExternalFinancier(
		token: string,
		type: HostedPageType,
		plan?: Plan
	): Promise<ChargebeeHostedPageFragment> {
		return this.createChargebeeHostedPageForExternalFinancierGQL
			.mutate({input: {token, type, plan}})
			.pipe(first())
			.pipe(extractData('createChargebeeHostedPageForExternalFinancier'))
			.toPromise();
	}

	checkoutSubscriptionForExternalFinancier(token: string, type: HostedPageType, plan: Plan): Promise<boolean> {
		return new Promise(resolve => {
			let success = false;
			this.chargebee.openCheckout({
				hostedPage: () => this.createHostedPageForExternalFinancier(token, type, plan),
				success: () => success = true,
				close: () => resolve(success)
			});
		});
	}

	async notifyEmployeeOfExternalPayment(token: string): Promise<void> {
		await this.notifyEmployeeOfExternalPaymentGQL.mutate({input: {token}}).toPromise();
	}

	checkoutExistingSubscription(plan: Plan) {
		this.chargebee.openCheckout({
			hostedPage: () => this.createHostedPage(HostedPageType.CHECKOUT_EXISTING, plan)
		});
	}

	managePaymentSources() {
		this.chargebee.openCheckout({
			hostedPage: () => this.createHostedPage(HostedPageType.MANAGE_PAYMENT_SOURCES)
		});
	}

	private async createPortalSession(): Promise<ChargebeePortalSessionFragment> {
		return this.createPortalSessionGql
			.mutate()
			.pipe(first())
			.pipe(extractData('createChargebeePortalSession'))
			.toPromise();
	}

	openChargebeePortal() {
		this.chargebee.setPortalSession(() => this.createPortalSession());
		const portal = this.chargebee.createChargebeePortal();
		portal.open();
	}

	private async createPortalSessionForExternalFinancier(token: string): Promise<ChargebeePortalSessionFragment> {
		return this.createChargebeePortalSessionForExternalFinancierGQL
			.mutate({input: {token}})
			.pipe(first())
			.pipe(extractData('createChargebeePortalSessionForExternalFinancier'))
			.toPromise();
	}

	openChargebeePortalForExternalFinancier(token: string) {
		this.chargebee.setPortalSession(() => this.createPortalSessionForExternalFinancier(token));
		const portal = this.chargebee.createChargebeePortal();
		portal.open();
	}

	getBrandSubscriptionState(): Observable<SubscriptionState> {
		return this.getBrandSubscriptionStateGql
			.fetch()
			.pipe(
				extractData('me'),
				map(me => me.employee.brand.subscriptionState)
			);
	}

	watchBrandSubscriptionState(): Observable<SubscriptionResult<SubscriptionStateChangedSubscription>> {
		return this.subscriptionStateChangedGQL.subscribe();
	}

	createOfflineSubscription(plan: Plan): Observable<boolean> {
		return this.createOfflineSubscriptionGQL
			.mutate({plan})
			.pipe(
				extractData('createOfflineSubscription'),
				map(data => data.success)
			);
	}

	reactivateOfflineSubscription(): Observable<boolean> {
		return this.reactivateOfflineSubscriptionGQL
			.mutate()
			.pipe(
				extractData('reactivateOfflineSubscription'),
				map(data => data.success)
			);
	}

	requestExternalPayment(input: RequestExternalPaymentInput): Observable<boolean> {
		return this.requestExternalPaymentGQL
			.mutate({input}).pipe(
				extractData('requestExternalPayment'),
				map(requestExternalPayment => requestExternalPayment.success)
			);
	}
}
