import {Directive, ElementRef, Input, OnDestroy, OnInit, Self} from '@angular/core';
import {CoachmarkService} from '@caroo/shared/coachmarks/coachmark.service';
import {PopoverDirective} from '@caroo/shared/components/popover';

@Directive({
	selector: '[carooPopover]'
})
export class CoachmarkDirective implements OnInit, OnDestroy {
	private readonly coachmark: { coachmark: CoachmarkDirective, popover: PopoverDirective };
	@Input()
	coachGroupId: string;

	@Input()
	coachIndex: number;

	constructor(
		public elementRef: ElementRef<HTMLElement>,
		private coachmarkService: CoachmarkService,
		@Self() popover: PopoverDirective
	) {
		this.coachmark = {coachmark: this, popover};
	}

	ngOnInit() {
		this.coachmarkService.registerCoachmark(this.coachmark);
	}

	ngOnDestroy() {
		this.coachmarkService.unregisterCoachmark(this.coachmark);
	}
}
