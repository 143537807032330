export class IdName {

	constructor(
		public id: number,
		public name: string,
		public numSelections: number,
		public maxNumSelections: number
	) {
	}
}
