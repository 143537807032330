import {Injectable} from '@angular/core';
import {LoadUserProfile, SetColleagues, SetUserProfile, UserActions, UserProfileActionTypes} from '@caroo/users/actions/user.actions';
import {UserService} from '@caroo/users/services/user.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, switchMap, throttleTime} from 'rxjs/operators';


@Injectable()
export class UserEffects {
	@Effect()
	loadUserProfile$ = this.actions$
		.pipe(ofType(UserProfileActionTypes.LoadUserProfile))
		.pipe(throttleTime(1000))
		.pipe(switchMap(() => this.userService.getUserProfile()))
		.pipe(map(userProfile => new SetUserProfile({userProfile})));

	@Effect()
	updateUserProfile$ = this.actions$
		.pipe(ofType(UserProfileActionTypes.UpdateUserProfile))
		.pipe(throttleTime(1000))
		.pipe(switchMap(({payload}) => this.userService.updateUserProfile(payload.name, payload.jobTitle)))
		.pipe(map(() => new LoadUserProfile()));

	@Effect()
	loadColleagues$ = this.actions$
		.pipe(ofType(UserProfileActionTypes.LoadColleagues))
		.pipe(throttleTime(1000))
		.pipe(switchMap(() => this.userService.getColleagues()))
		.pipe(map(colleagues => new SetColleagues({colleagues})));

	constructor(private actions$: Actions<UserActions>, private userService: UserService) {
	}

}
