var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HttpErrorResponse } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { LocalStorage } from '@caroo/storage/decorator';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
export class InvalidTokenInterceptor {
    constructor(angularFireAuth, router) {
        this.angularFireAuth = angularFireAuth;
        this.router = router;
    }
    intercept(req, next) {
        return next
            .handle(req)
            .pipe(catchError(error => of(error)))
            .pipe(map((response) => {
            if (response instanceof HttpErrorResponse && (req.url.includes('caroo') || new URL(req.url).port === '3000')) {
                if ((response.status === 401 && !req.url.includes('login')) || response.status === 403) {
                    this.angularFireAuth.auth.signOut().catch(console.error);
                    this.token = undefined;
                    this.router.navigateByUrl('/login').catch(console.error);
                    return response;
                }
                else {
                    throw response;
                }
            }
            else {
                return response;
            }
        }));
    }
}
__decorate([
    LocalStorage,
    __metadata("design:type", String)
], InvalidTokenInterceptor.prototype, "token", void 0);
