import {enableProdMode} from '@angular/core';
import {FIREBASE_OPTIONS} from '@angular/fire';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {ConfigService} from '@caroo/config/config.service';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic([{
	provide: FIREBASE_OPTIONS,
	useValue: new ConfigService().firebase
}])
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
