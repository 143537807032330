var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InviteColleagueGQL, USE_BRAND_API } from '@caroo/graphql-new';
export class InviteColleaguesDialogComponent {
    constructor(formBuilder, inviteColleagueGQL, matDialog, matDialogRef, matSnackBar) {
        this.formBuilder = formBuilder;
        this.inviteColleagueGQL = inviteColleagueGQL;
        this.matDialog = matDialog;
        this.matDialogRef = matDialogRef;
        this.matSnackBar = matSnackBar;
        this.formGroup = this.formBuilder.group({
            email0: new FormControl('', [Validators.required, Validators.email]),
        });
        this.formControlCount = 1;
        this.formControlStatusMap = new Map();
        this.successfullySubmittedFormControlNames = [];
        this.apiErrors = {};
        this.submitting = false;
    }
    get controls() {
        return Object.entries(this.formGroup.controls);
    }
    getFormControlStatus(name) {
        return this.formControlStatusMap.get(name);
    }
    addFormControl() {
        this.formGroup.addControl(`email${this.formControlCount}`, new FormControl('', [Validators.required, Validators.email]));
        this.formControlCount++;
    }
    trackFormControl(index) {
        return index;
    }
    removeFormControl(name) {
        this.formGroup.removeControl(name);
    }
    sendInvites() {
        return __awaiter(this, void 0, void 0, function* () {
            this.submitting = true;
            this.apiErrors = {};
            const entries = Object.entries(this.formGroup.controls).filter(([name]) => !this.successfullySubmittedFormControlNames.includes(name));
            for (const [name] of entries) {
                this.formControlStatusMap.set(name, 'pending');
            }
            for (const [name, formControl] of entries) {
                try {
                    yield this.inviteColleagueGQL.mutate({ input: { email: formControl.value } }, USE_BRAND_API).toPromise();
                    this.formControlStatusMap.set(name, 'success');
                }
                catch (err) {
                    this.formControlStatusMap.set(name, 'failure');
                    const errors = err.graphQLErrors;
                    if (errors && errors.length > 0) {
                        this.apiErrors[name] = errors[0].message;
                    }
                    else {
                        this.apiErrors[name] = 'An unknown error occurred';
                    }
                }
            }
            for (const [name, formControl] of entries) {
                if (this.formControlStatusMap.get(name) === 'success') {
                    this.successfullySubmittedFormControlNames.push(name);
                    formControl.disable();
                }
                else {
                    this.formControlStatusMap.delete(name);
                }
            }
            this.submitting = false;
            if (Object.keys(this.formGroup.controls).length === 0) {
                this.matSnackBar.open('Your colleagues have been sent emails, inviting them to join you on Caroo', 'Close', {
                    duration: 3000,
                });
                this.matDialogRef.close();
            }
        });
    }
}
