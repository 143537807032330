/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../resource/resource.component.ngfactory";
import * as i3 from "../resource/resource.component";
import * as i4 from "../youtube-input-field/youtube.service";
import * as i5 from "./logo.component";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-resource", [], null, null, null, i2.View_ResourceComponent_0, i2.RenderType_ResourceComponent)), i1.ɵdid(1, 704512, null, 0, i3.ResourceComponent, [i1.ChangeDetectorRef, i4.YoutubeService], { resource: [0, "resource"], fallback: [1, "fallback"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource; var currVal_1 = _co.fallback; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 49152, null, 0, i5.LogoComponent, [], null, null)], null, null); }
var LogoComponentNgFactory = i1.ɵccf("caroo-logo", i5.LogoComponent, View_LogoComponent_Host_0, { resource: "resource" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
