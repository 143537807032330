import {Component, Inject} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PaymentDetailsSentToColleagueDialogComponent} from '@caroo/payment/payment-details-sent-to-colleague-dialog';
import {PaymentService} from '@caroo/payment/payment.service';
import {getPlanText} from '@caroo/payment/util';
import {Plan} from '../../../generated/graphql';

export interface SendPaymentDetailsToColleagueDialogData {
	plan: Plan;
}

@Component({
	selector: 'caroo-send-payment-details-to-colleague-dialog',
	templateUrl: './send-payment-details-to-colleague-dialog.component.html',
	styleUrls: ['./send-payment-details-to-colleague-dialog.component.scss']
})
export class SendPaymentDetailsToColleagueDialogComponent {
	readonly plan = new FormControl(getPlanText(this.data.plan));
	readonly employeeName = new FormControl('', [Validators.required]);
	readonly financierName = new FormControl('', [Validators.required]);
	readonly financierEmail = new FormControl('', [Validators.required, Validators.email]);
	readonly confirmFinancierEmail = new FormControl('', [Validators.required, Validators.email, (control: AbstractControl) => {
		if (control.value !== this.financierEmail.value) {
			return {
				emailsMatch: false
			};
		} else {
			return null;
		}
	}]);
	readonly note = new FormControl(undefined);
	readonly formGroup = this.formBuilder.group({
		plan: this.plan,
		employeeName: this.employeeName,
		financierName: this.financierName,
		financierEmail: this.financierEmail,
		confirmFinancierEmail: this.confirmFinancierEmail,
		note: this.note
	});

	constructor(
		private readonly formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private readonly data: SendPaymentDetailsToColleagueDialogData,
		private readonly matDialog: MatDialog,
		private readonly matDialogRef: MatDialogRef<SendPaymentDetailsToColleagueDialogComponent>,
		private readonly paymentService: PaymentService
	) {
	}

	async submit(): Promise<void> {
		await this.paymentService.requestExternalPayment({
			plan: this.data.plan,
			employeeName: this.employeeName.value,
			externalFinancierName: this.financierName.value,
			externalFinancierEmail: this.financierEmail.value,
			note: this.note.value,
		}).toPromise();
		this.matDialog.open(PaymentDetailsSentToColleagueDialogComponent);
		this.close();
	}

	close(): void {
		this.matDialogRef.close();
	}
}
