import {Profile} from '../../shared/models';
import {ProfileActions, ProfileActionTypes} from '../actions/profile.actions';

export interface ProfileState {
	profile?: Profile;
}

export const initialState: ProfileState = {};

export function reducer(state = initialState, action: ProfileActions): ProfileState {
	switch (action.type) {
		case ProfileActionTypes.SetProfile:
			return {
				...state,
				profile: action.payload.profile
			};
		case ProfileActionTypes.ClearProfile:
			return {
				...state,
				profile: undefined
			};
		default:
			return state;
	}
}
