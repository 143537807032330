// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {Environment} from './env';

export const environment: Environment = {
	production: true,
	baseUrl: undefined,
	graphqlApiUrl: undefined,
	socketUrl: undefined,
	googleApisKey: 'AIzaSyB8IQ3HO14U2sAWep-Q-GZ0LEyX3-kAdzM',
	firebase: undefined
};
