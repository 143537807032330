import {Colleague} from '@caroo/shared/models';
import {UserProfile} from '@caroo/users/models/user-profile';
import {UserActions, UserProfileActionTypes} from '../actions/user.actions';

export interface UserState {
	userProfile?: UserProfile;
	colleagues: Colleague[];
}

export const initialState: UserState = {
	colleagues: []
};

export function reducer(state = initialState, action: UserActions): UserState {
	switch (action.type) {
		case UserProfileActionTypes.SetUserProfile:
			return {
				...state,
				userProfile: action.payload.userProfile
			};
		case UserProfileActionTypes.SetColleagues:
			return {
				...state,
				colleagues: action.payload.colleagues
			};
		default:
			return state;
	}
}
