import {AngularFireAuth} from '@angular/fire/auth';
import {setContext} from 'apollo-link-context';
import {first} from 'rxjs/operators';

export const authLink = (angularFireAuth: AngularFireAuth) => setContext(async (_, {headers}) => {
	const currentUser = await angularFireAuth.user.pipe(first()).toPromise();
	const token = currentUser ? await currentUser.getIdToken() : localStorage.getItem('token');
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : '',
		}
	};
});
