import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@caroo/shared/button/button.module';
import {InfoDialogComponent} from '@caroo/shared/info-dialog/info-dialog.component';
import {LottieModule} from '@caroo/shared/lottie';

@NgModule({
	declarations: [InfoDialogComponent],
	imports: [
		ButtonModule,
		LottieModule,
		CommonModule,
		RouterModule
	],
	exports: [InfoDialogComponent],
	entryComponents: [InfoDialogComponent]
})
export class InfoDialogModule {
}
