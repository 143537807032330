import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class YoutubeService {
    constructor(http) {
        this.http = http;
    }
    getVideoSnippet(videoId) {
        return this.http.get(`https://www.googleapis.com/youtube/v3/videos?key=AIzaSyAbhZsdHomM03L5itBLbsUv-OtpvXrFOSU&part=snippet&id=${videoId}`, {
            headers: {
                Accept: 'application/json'
            }
        });
    }
}
YoutubeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YoutubeService_Factory() { return new YoutubeService(i0.ɵɵinject(i1.HttpClient)); }, token: YoutubeService, providedIn: "root" });
