import {ConnectedPosition, Overlay, OverlayConfig, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {Injectable, Injector} from '@angular/core';
import {PopoverComponent} from './popover.component';
import {PopoverContent, PopoverRef} from './popover.ref';

export interface PopoverParams<T> {
	origin: HTMLElement;
	content: PopoverContent;
	data?: T;
	width?: string | number;
	height?: string | number;
	hasBackdrop?: boolean;
	arrowPosition: 'left' | 'centre' | 'right';
	positions?: ConnectedPosition[];
	offsetX?: number;
}

@Injectable({providedIn: 'root'})
export class PopoverService {
	constructor(private overlay: Overlay, private injector: Injector) {
	}

	private static createInjector(popoverRef: PopoverRef, injector: Injector) {
		const tokens = new WeakMap([[PopoverRef, popoverRef]]);
		return new PortalInjector(injector, tokens);
	}

	open<T>({origin, content, data, width, height, hasBackdrop, arrowPosition, positions}: PopoverParams<T>) {
		const overlayRef = this.overlay.create(this.getOverlayConfig(origin, width, height, hasBackdrop, positions));
		const popoverRef = new PopoverRef(overlayRef, content, data, arrowPosition);
		const injector = PopoverService.createInjector(popoverRef, this.injector);
		overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
		return popoverRef;
	}

	private getOverlayPosition(origin: HTMLElement, positions?: ConnectedPosition[]): PositionStrategy {
		return this.overlay.position()
			.flexibleConnectedTo(origin)
			.withPositions(positions || []);
	}

	private getOverlayConfig(
		origin: HTMLElement,
		width?: number | string,
		height?: number | string,
		hasBackdrop?: boolean,
		positions?: ConnectedPosition[]
	): OverlayConfig {
		return new OverlayConfig({
			width,
			height,
			hasBackdrop: hasBackdrop,
			backdropClass: 'popover-backdrop',
			positionStrategy: this.getOverlayPosition(origin, positions),
			scrollStrategy: this.overlay.scrollStrategies.reposition()
		});
	}

}
