import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {DashboardActions, DashboardActionTypes, SetDashboard, SetNumCandidates} from '../actions';
import {DashboardService} from '../services';


@Injectable()
export class DashboardEffects {
	@Effect()
	loadDashboard$ = this.actions$
		.pipe(ofType(DashboardActionTypes.LoadDashboard))
		.pipe(switchMap(() => this.dashboardService.getDashboard()))
		.pipe(map(dashboard => new SetDashboard({dashboard})));

	@Effect()
	loadNumCandidates$ = this.actions$
		.pipe(ofType(DashboardActionTypes.LoadNumCandidates))
		.pipe(switchMap(() => this.dashboardService.getNumCandidates()))
		.pipe(map(numCandidates => new SetNumCandidates({numCandidates})));

	constructor(private actions$: Actions<DashboardActions>, private dashboardService: DashboardService) {
	}

}
