import {Action} from '@ngrx/store';
import {Dashboard, NumCandidates} from '../services';

export enum DashboardActionTypes {
	LoadDashboard = '[Dashboard] Load Dashboard',
	SetDashboard = '[Dashboard] Set Dashboard',
	LoadNumCandidates = '[Dashboard] Load Num Candidates',
	SetNumCandidates = '[Dashboard] Set Num Candidates'
}

export class LoadDashboard implements Action {
	readonly type = DashboardActionTypes.LoadDashboard;
}

export class SetDashboard implements Action {
	readonly type = DashboardActionTypes.SetDashboard;

	constructor(public payload: { dashboard: Dashboard }) {
	}
}

export class LoadNumCandidates {
	readonly type = DashboardActionTypes.LoadNumCandidates;
}

export class SetNumCandidates implements Action {
	readonly type = DashboardActionTypes.SetNumCandidates;

	constructor(public payload: { numCandidates: NumCandidates }) {
	}
}

export type DashboardActions = LoadDashboard | SetDashboard | LoadNumCandidates | SetNumCandidates;
