import {NgModule} from '@angular/core';
import {DashboardEffects} from '@caroo/dashboard/effects/dashboard.effects';
import {reducer} from '@caroo/dashboard/reducers/dashboard.reducer';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

@NgModule({
	imports: [
		StoreModule.forFeature('dashboard', reducer),
		EffectsModule.forFeature([DashboardEffects])
	]
})
export class DashboardStateModule {
}
