<ng-container>
	<h1 class="heading">Invite colleague to complete payment</h1>
	<p class="body1">Pop in the details of the person who will pay for Caroo. Once they have paid, we will email you to confirm and your jobs can go live to candidates.</p>
	<form [formGroup]="formGroup" (ngSubmit)="submit()">
		<caroo-form-field>
			<label carooInputLabel>Subscription type</label>
			<input type="text" [formControl]="plan" carooInput readonly/>
		</caroo-form-field>
		<caroo-form-field>
			<label carooInputLabel>Your Name *</label>
			<input type="text" [formControl]="employeeName" carooInput/>
		</caroo-form-field>
		<caroo-form-field>
			<label carooInputLabel>Your colleague's name *</label>
			<input type="text" [formControl]="financierName" carooInput/>
		</caroo-form-field>
		<caroo-form-field>
			<label carooInputLabel>Email Address *</label>
			<input type="email" [formControl]="financierEmail" carooInput/>
		</caroo-form-field>
		<caroo-form-field>
			<label carooInputLabel>Confirm Email Address *</label>
			<input type="email" [formControl]="confirmFinancierEmail" carooInput/>
		</caroo-form-field>
		<caroo-form-field>
			<label carooInputLabel>Add a note</label>
			<textarea [formControl]="note" carooInput cdkTextareaAutosize>Hi Dave, could you pay this subscription for us please?</textarea>
		</caroo-form-field>
		<button type="submit" [disabled]="formGroup.invalid" caroo-primary-button>Confirm</button>
	</form>
	<mat-icon class="close" (click)="close()">close</mat-icon>
</ng-container>
