import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {iif, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {LocalStorage} from '../../storage/decorator';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
	@LocalStorage
	private readonly token: string;

	constructor(
		private readonly angularFireAuth: AngularFireAuth,
		private readonly router: Router
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.angularFireAuth.user
			.pipe(
				switchMap(user =>
					iif(() => !!this.token || !!user || state.url.includes('register') || state.url.includes('login'),
						of(true),
						of(this.router.createUrlTree(['register']))
					)
				)
			);
	}
}
