import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserState} from './user.reducer';

const usersFeatureSelector = createFeatureSelector<UserState>('users');

export const selectUserProfile = createSelector(usersFeatureSelector, state => state.userProfile);

export const selectColleagues = createSelector(usersFeatureSelector, state => state.colleagues);

export const selectNonPendingColleagues = createSelector(
	usersFeatureSelector,
	state => state.colleagues.filter(colleague => !colleague.pending)
);
