import {Injectable} from '@angular/core';
import {PaymentActions, PaymentActionTypes, SetBrandSubscriptionState} from '@caroo/payment/actions/payment.actions';
import {PaymentService} from '@caroo/payment/payment.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, switchMap, throttleTime} from 'rxjs/operators';


@Injectable()
export class PaymentEffects {
	@Effect()
	loadBrandSubscriptionState$ = this.actions$
		.pipe(ofType(PaymentActionTypes.LoadBrandSubscriptionState))
		.pipe(throttleTime(1000))
		.pipe(switchMap(() => this.paymentService.getBrandSubscriptionState()))
		.pipe(map(subscriptionState => new SetBrandSubscriptionState({subscriptionState})));

	constructor(private actions$: Actions<PaymentActions>, private paymentService: PaymentService) {
	}
}
