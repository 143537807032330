import {ConnectedPosition} from '@angular/cdk/overlay';
import {Directive, ElementRef, HostListener, Input, TemplateRef} from '@angular/core';
import {PopoverRef} from './popover.ref';
import {PopoverService} from './popover.service';

@Directive({
	selector: '[carooPopover]'
})
export class PopoverDirective {
	private popoverRef: PopoverRef | undefined;

	@Input('carooPopover')
	popover: TemplateRef<any>;

	@Input()
	mode: 'hover' | 'click' | 'coachmark';

	@Input()
	positions: ConnectedPosition[];

	@Input()
	position: 'left' | 'centre' | 'right';

	constructor(public elementRef: ElementRef<HTMLElement>, private popoverService: PopoverService) {
	}

	private getPositions(): ConnectedPosition[] {
		switch (this.position) {
			case 'left':
				return [{
					originX: 'center',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
					offsetX: 22
				}];
			case 'centre':
				return [{
					originX: 'center',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
					offsetX: -22
				}];
			case 'right':
				return [{
					originX: 'center',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
					offsetX: -22
				}];
			default:
				return [];
		}
	}

	openPopover(hasBackdrop: boolean) {
		this.popoverRef = this.popoverService.open({
			origin: this.elementRef.nativeElement,
			content: this.popover,
			hasBackdrop: hasBackdrop,
			positions: this.positions || this.getPositions(),
			arrowPosition: this.position
		});
	}

	closePopover() {
		if (this.popoverRef) {
			this.popoverRef.close();
		}
	}

	@HostListener('click')
	onClick() {
		if (this.mode === 'click') {
			this.openPopover(true);
		}
	}

	@HostListener('mouseenter')
	onMouseEnter() {
		if (this.mode === 'hover') {
			this.openPopover(false);
		}
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.mode === 'hover') {
			if (!!this.popoverRef) {
				this.popoverRef.close();
			}
		}
	}
}
