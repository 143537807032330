/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lottie.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lottie.component";
var styles_LottieComponent = [i0.styles];
var RenderType_LottieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LottieComponent, data: {} });
export { RenderType_LottieComponent as RenderType_LottieComponent };
export function View_LottieComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 3, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "width": 0, "height": 1, "overflow": 2, "margin": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.viewWidth, _co.viewHeight, "hidden", "0 auto"); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LottieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-lottie", [], null, null, null, View_LottieComponent_0, RenderType_LottieComponent)), i1.ɵdid(1, 245760, null, 0, i3.LottieComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LottieComponentNgFactory = i1.ɵccf("caroo-lottie", i3.LottieComponent, View_LottieComponent_Host_0, { options: "options", width: "width", height: "height" }, { animCreated: "animCreated" }, []);
export { LottieComponentNgFactory as LottieComponentNgFactory };
