import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'caroo-payment-details-sent-to-colleague-dialog',
	templateUrl: './payment-details-sent-to-colleague-dialog.component.html',
	styleUrls: ['./payment-details-sent-to-colleague-dialog.component.scss']
})
export class PaymentDetailsSentToColleagueDialogComponent {
	readonly lottieOptions1 = {
		path: 'assets/animations/setup-profile.json',
		renderer: 'canvas',
		autoplay: true,
		loop: true,
	};
	readonly lottieOptions2 = {
		path: 'assets/animations/no-roles.json',
		renderer: 'canvas',
		autoplay: true,
		loop: true
	};
	readonly lottieOptions3 = {
		path: 'assets/animations/no-messages.json',
		renderer: 'canvas',
		autoplay: true,
		loop: true
	};

	constructor(
		private readonly matDialogRef: MatDialogRef<PaymentDetailsSentToColleagueDialogComponent>,
	) {
	}

	close(): void {
		this.matDialogRef.close();
	}
}
