<ng-container>
	<h1 class="heading">Payment details have been sent to your colleague</h1>
	<p class="body1">What you can do in the meantime:</p>
	<div class="grid">
		<caroo-lottie [width]="187" [height]="187" [options]="lottieOptions1"></caroo-lottie>
		<h2 class="body1">Complete your brand profile</h2>
		<a routerLink="/profile" (click)="close()">
			<button caroo-primary-button>Go To Brand Profile</button>
		</a>
		<caroo-lottie [width]="187" [height]="187" [options]="lottieOptions2"></caroo-lottie>
		<h2 class="body1">Create jobs</h2>
		<a routerLink="/roles/add" (click)="close()">
			<button caroo-primary-button>Create First Job</button>
		</a>
		<caroo-lottie [width]="187" [height]="187" [options]="lottieOptions3"></caroo-lottie>
		<h2 class="body1" (click)="close()">Contact Caroo for support</h2>
		<a href="mailto:jobs@caroo.co.uk">
			<button caroo-primary-button>Email Caroo</button>
		</a>
	</div>
	<mat-icon class="close" (click)="close()">close</mat-icon>
</ng-container>
