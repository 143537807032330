import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class CroutonService {
    constructor() {
        this.source = new BehaviorSubject(null);
        this.message = this.source.asObservable();
    }
    sendMessage(message) {
        this.source.next(message);
    }
}
CroutonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CroutonService_Factory() { return new CroutonService(); }, token: CroutonService, providedIn: "root" });
export var Crouton;
(function (Crouton) {
    let Type;
    (function (Type) {
        Type[Type["SUCCESS"] = 0] = "SUCCESS";
        Type[Type["ERROR"] = 1] = "ERROR";
    })(Type = Crouton.Type || (Crouton.Type = {}));
})(Crouton || (Crouton = {}));
