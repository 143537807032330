/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-details-sent-to-colleague-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/lottie/lottie.component.ngfactory";
import * as i3 from "../../shared/lottie/lottie.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../shared/button/button.component.ngfactory";
import * as i7 from "../../shared/button/button.component";
import * as i8 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./payment-details-sent-to-colleague-dialog.component";
import * as i11 from "@angular/material/dialog";
var styles_PaymentDetailsSentToColleagueDialogComponent = [i0.styles];
var RenderType_PaymentDetailsSentToColleagueDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentDetailsSentToColleagueDialogComponent, data: {} });
export { RenderType_PaymentDetailsSentToColleagueDialogComponent as RenderType_PaymentDetailsSentToColleagueDialogComponent };
export function View_PaymentDetailsSentToColleagueDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Payment details have been sent to your colleague"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "body1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What you can do in the meantime:"])), (_l()(), i1.ɵeld(5, 0, null, null, 26, "div", [["class", "grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "caroo-lottie", [], null, null, null, i2.View_LottieComponent_0, i2.RenderType_LottieComponent)), i1.ɵdid(7, 245760, null, 0, i3.LottieComponent, [], { options: [0, "options"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [["class", "body1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Complete your brand profile"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [["routerLink", "/profile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, null, null, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(13, 49152, null, 0, i7.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Go To Brand Profile"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "caroo-lottie", [], null, null, null, i2.View_LottieComponent_0, i2.RenderType_LottieComponent)), i1.ɵdid(16, 245760, null, 0, i3.LottieComponent, [], { options: [0, "options"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "h2", [["class", "body1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create jobs"])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "a", [["routerLink", "/roles/add"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, null, null, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(22, 49152, null, 0, i7.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Create First Job"])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "caroo-lottie", [], null, null, null, i2.View_LottieComponent_0, i2.RenderType_LottieComponent)), i1.ɵdid(25, 245760, null, 0, i3.LottieComponent, [], { options: [0, "options"], width: [1, "width"], height: [2, "height"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "h2", [["class", "body1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Caroo for support"])), (_l()(), i1.ɵeld(28, 0, null, null, 3, "a", [["href", "mailto:jobs@caroo.co.uk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "button", [["caroo-primary-button", ""]], null, null, null, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i1.ɵdid(30, 49152, null, 0, i7.ButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Email Caroo"])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "mat-icon", [["class", "close mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(33, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lottieOptions1; var currVal_1 = 187; var currVal_2 = 187; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = "/profile"; _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.lottieOptions2; var currVal_7 = 187; var currVal_8 = 187; _ck(_v, 16, 0, currVal_6, currVal_7, currVal_8); var currVal_11 = "/roles/add"; _ck(_v, 20, 0, currVal_11); var currVal_12 = _co.lottieOptions3; var currVal_13 = 187; var currVal_14 = 187; _ck(_v, 25, 0, currVal_12, currVal_13, currVal_14); _ck(_v, 33, 0); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_9 = i1.ɵnov(_v, 20).target; var currVal_10 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_9, currVal_10); var currVal_15 = i1.ɵnov(_v, 33).inline; var currVal_16 = (((i1.ɵnov(_v, 33).color !== "primary") && (i1.ɵnov(_v, 33).color !== "accent")) && (i1.ɵnov(_v, 33).color !== "warn")); _ck(_v, 32, 0, currVal_15, currVal_16); }); }
export function View_PaymentDetailsSentToColleagueDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "caroo-payment-details-sent-to-colleague-dialog", [], null, null, null, View_PaymentDetailsSentToColleagueDialogComponent_0, RenderType_PaymentDetailsSentToColleagueDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.PaymentDetailsSentToColleagueDialogComponent, [i11.MatDialogRef], null, null)], null, null); }
var PaymentDetailsSentToColleagueDialogComponentNgFactory = i1.ɵccf("caroo-payment-details-sent-to-colleague-dialog", i10.PaymentDetailsSentToColleagueDialogComponent, View_PaymentDetailsSentToColleagueDialogComponent_Host_0, {}, {}, []);
export { PaymentDetailsSentToColleagueDialogComponentNgFactory as PaymentDetailsSentToColleagueDialogComponentNgFactory };
