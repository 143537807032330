import {Action} from '@ngrx/store';
import {Profile} from '../../shared/models';

export enum ProfileActionTypes {
	LoadProfile = '[Profile] Load Profile',
	SetProfile = '[Profile] Set Profile',
	ClearProfile = '[Profile] Clear Profile'
}

export class LoadProfile implements Action {
	readonly type = ProfileActionTypes.LoadProfile;
}

export class SetProfile implements Action {
	readonly type = ProfileActionTypes.SetProfile;

	constructor(public payload: { profile: Profile }) {
	}
}

export class ClearProfile implements Action {
	readonly type = ProfileActionTypes.ClearProfile;
}

export type ProfileActions = LoadProfile | SetProfile | ClearProfile;
