import { map } from 'rxjs/operators';
import { Plan, SubscriptionState } from '../../../generated/graphql';
export function isSubscriptionActive(subscriptionState) {
    if (subscriptionState) {
        switch (subscriptionState) {
            case SubscriptionState.ACTIVE:
            case SubscriptionState.FREE_TRIAL:
            case SubscriptionState.BYPASS:
                return true;
            case SubscriptionState.CANCELLED:
            case SubscriptionState.CANCELLED_PAYMENT_FAILED:
            case SubscriptionState.NONE:
                return false;
        }
    }
    else {
        return false;
    }
}
export function isSubscriptionCancelled(subscriptionState) {
    if (subscriptionState) {
        switch (subscriptionState) {
            case SubscriptionState.CANCELLED:
            case SubscriptionState.CANCELLED_PAYMENT_FAILED:
                return true;
            case SubscriptionState.ACTIVE:
            case SubscriptionState.FREE_TRIAL:
            case SubscriptionState.NONE:
            case SubscriptionState.BYPASS:
                return false;
        }
    }
    else {
        return false;
    }
}
const ɵ0 = (subscriptionState) => isSubscriptionActive(subscriptionState);
export const mapSubscriptionStateToIsActive = map(ɵ0);
export function getPlanText(plan) {
    switch (plan) {
        case Plan.SMALL_BUSINESS:
            return 'Monthly £399+VAT/month';
        case Plan.MEDIUM_BUSINESS:
            return 'Quarterly £199+VAT/month';
        case Plan.LARGE_BUSINESS:
            return 'Yearly £167+VAT/month';
        case Plan.MANAGED_SERVICE:
            return 'Full Service £250+VAT/month';
        default:
            throw new Error(`Unknown plan: ${plan}`);
    }
}
export { ɵ0 };
