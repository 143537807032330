import {NgModule} from '@angular/core';
import {PaymentEffects} from '@caroo/payment/effects/payment.effects';
import {reducer} from '@caroo/payment/reducers/payment.reducer';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

@NgModule({
	imports: [
		StoreModule.forFeature('payment', reducer),
		EffectsModule.forFeature([PaymentEffects])
	]
})
export class PaymentStateModule {
}
