import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {AcceptInviteComponent} from '@caroo/auth/components/accept-invite/accept-invite.component';
import {ButtonModule} from '@caroo/shared/button/button.module';
import {CarooFormsModule} from '@caroo/shared/forms';
import {InfoDialogModule} from '@caroo/shared/info-dialog';
import {LottieModule} from '@caroo/shared/lottie';

@NgModule({
	declarations: [AcceptInviteComponent],
	imports: [
		ButtonModule,
		CarooFormsModule,
		InfoDialogModule,
		LottieModule,
		MatDialogModule,
		MatIconModule,
		ReactiveFormsModule,
		RouterModule.forChild([{
			path: 'invites/accept',
			component: AcceptInviteComponent
		}])
	],
	exports: [AcceptInviteComponent]
})
export class AcceptInviteModule {
}
