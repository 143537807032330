var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@caroo/notifications/services/notification.service';
import { getContentForNotification, getLinkForNotification, getPlainTitleForNotification } from '@caroo/notifications/util/notification-utils';
import { ElectronService } from '@caroo/services/electron.service';
import { LocalStorage } from '@caroo/storage/decorator';
import { filterDefined } from '@caroo/util/filters';
import { first, map } from 'rxjs/operators';
export class NotificationListComponent {
    constructor(notificationService, ngZone, router, electronService) {
        this.notificationService = notificationService;
        this.ngZone = ngZone;
        this.router = router;
        this.electronService = electronService;
        this.notificationsQueryRef = this.notificationService.getNotifications();
        this.notifications$ = this.notificationsQueryRef.valueChanges.pipe(map(value => value.data), filterDefined(), map(data => data.me.employee.notifications));
        this.numUnreadNotifications$ = this.notifications$.pipe(map(notifications => notifications.filter(notification => !notification.read).length));
        this.newNotifications$ = this.notificationService
            .subscribeToNewNotifications()
            .pipe(map(result => result.data), filterDefined());
        this.notificationClicked = new EventEmitter();
    }
    ngOnInit() {
        this.newNotificationsSubscription = this.newNotifications$.subscribe(data => {
            const notification = data.notificationReceived;
            this.notificationsQueryRef.refetch().catch(console.error);
            const webNotification = new Notification(getPlainTitleForNotification(notification), {
                body: getContentForNotification(notification),
                requireInteraction: true
            });
            webNotification.onclick = () => {
                if (this.electronService.isElectron()) {
                    this.electronService.remote.getCurrentWindow().show();
                }
                this.notificationService
                    .markNotificationAsRead(notification.id)
                    .then(() => this.notificationsQueryRef.refetch())
                    .catch(console.error);
                webNotification.close();
                window.focus();
                this.ngZone.run(() => this.router.navigateByUrl(getLinkForNotification(notification)).catch(console.error)).catch(console.error);
            };
        });
        if (this.electronService.isElectron()) {
            this.badgeNumSubscription = this.numUnreadNotifications$.subscribe(num => this.electronService.setBadgeNumber(num));
        }
    }
    ngOnDestroy() {
        if (this.electronService.isElectron()) {
            this.electronService.setBadgeNumber(0);
        }
        if (this.newNotificationsSubscription) {
            this.newNotificationsSubscription.unsubscribe();
        }
        if (this.badgeNumSubscription) {
            this.badgeNumSubscription.unsubscribe();
        }
    }
    markAllNotificationsAsRead() {
        this.notificationService
            .markAllNotificationsAsRead()
            .pipe(first())
            .subscribe(() => {
            this.notificationsQueryRef
                .refetch()
                .catch(console.error);
        });
    }
    onNotificationClicked(notification) {
        this.notificationService
            .markNotificationAsRead(notification.id)
            .then(() => this.notificationsQueryRef.refetch())
            .catch(console.error);
        this.notificationClicked.emit();
    }
}
__decorate([
    LocalStorage,
    __metadata("design:type", String)
], NotificationListComponent.prototype, "token", void 0);
