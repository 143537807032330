import {Component, EventEmitter, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from '@caroo/notifications/services/notification.service';
import {getContentForNotification, getLinkForNotification, getPlainTitleForNotification} from '@caroo/notifications/util/notification-utils';
import {ElectronService} from '@caroo/services/electron.service';
import {LocalStorage} from '@caroo/storage/decorator';
import {filterDefined} from '@caroo/util/filters';
import {Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {EmployeeNotificationFragment} from '../../../../generated/graphql';

@Component({
	selector: 'caroo-notification-list',
	templateUrl: './notification-list.component.html',
	styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {
	@LocalStorage
	private token: string;
	private newNotificationsSubscription?: Subscription;
	private badgeNumSubscription?: Subscription;
	private readonly notificationsQueryRef = this.notificationService.getNotifications();
	readonly notifications$ = this.notificationsQueryRef.valueChanges.pipe(
		map(value => value.data),
		filterDefined(),
		map(data => data.me.employee.notifications),
	);
	readonly numUnreadNotifications$ = this.notifications$.pipe(
		map(notifications => notifications.filter(notification => !notification.read).length)
	);

	private readonly newNotifications$ = this.notificationService
		.subscribeToNewNotifications()
		.pipe(
			map(result => result.data),
			filterDefined()
		);

	@Output()
	notificationClicked = new EventEmitter();

	constructor(
		private readonly notificationService: NotificationService,
		private ngZone: NgZone,
		private router: Router,
		private electronService: ElectronService
	) {
	}

	ngOnInit() {
		this.newNotificationsSubscription = this.newNotifications$.subscribe(data => {
			const notification = data.notificationReceived;
			this.notificationsQueryRef.refetch().catch(console.error);
			const webNotification = new Notification(getPlainTitleForNotification(notification), {
				body: getContentForNotification(notification),
				requireInteraction: true
			});
			webNotification.onclick = () => {
				if (this.electronService.isElectron()) {
					this.electronService.remote.getCurrentWindow().show();
				}
				this.notificationService
					.markNotificationAsRead(notification.id)
					.then(() => this.notificationsQueryRef.refetch())
					.catch(console.error);
				webNotification.close();
				window.focus();
				this.ngZone.run(() => this.router.navigateByUrl(getLinkForNotification(notification)).catch(console.error)).catch(console.error);
			};
		});
		if (this.electronService.isElectron()) {
			this.badgeNumSubscription = this.numUnreadNotifications$.subscribe(num => this.electronService.setBadgeNumber(num));
		}
	}

	ngOnDestroy() {
		if (this.electronService.isElectron()) {
			this.electronService.setBadgeNumber(0);
		}
		if (this.newNotificationsSubscription) {
			this.newNotificationsSubscription.unsubscribe();
		}
		if (this.badgeNumSubscription) {
			this.badgeNumSubscription.unsubscribe();
		}
	}

	markAllNotificationsAsRead() {
		this.notificationService
			.markAllNotificationsAsRead()
			.pipe(first())
			.subscribe(() => {
				this.notificationsQueryRef
					.refetch()
					.catch(console.error);
			});
	}

	onNotificationClicked(notification: EmployeeNotificationFragment) {
		this.notificationService
			.markNotificationAsRead(notification.id)
			.then(() => this.notificationsQueryRef.refetch())
			.catch(console.error);
		this.notificationClicked.emit();
	}
}
