import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LoadingService} from '../loading.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	private requests: { id: number; url: string; timer: any }[] = [];
	private counter = 0;

	constructor(private loaderService: LoadingService) {
	}

	private updateLoadingState() {
		this.loaderService.loading.next(this.requests.length > 0);
	}

	private registerRequest(id: number, url: string) {
		this.requests.push({id, url: url, timer: setTimeout(() => this.removeRequestWithId(id), 5000)});
		this.updateLoadingState();
	}

	private removeRequestWithId(id: number) {
		const index = this.requests.findIndex(request => request.id === id);
		if (index !== -1) {
			clearTimeout(this.requests[index].timer);
			this.requests.splice(index, 1);
			this.updateLoadingState();
		}
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const id = this.counter++;
		if (req.url.startsWith(environment.baseUrl)) {
			this.registerRequest(id, req.url);
		}
		return next.handle(req).pipe(tap((value: HttpEvent<any>) => {
			const response = (value as HttpResponse<any>);
			if (response.body && response.url.startsWith(environment.baseUrl)) {
				this.removeRequestWithId(id);
			}
		})).pipe(catchError(error => {
			const response = error as HttpErrorResponse;
			if (response.error && response.url.startsWith(environment.baseUrl)) {
				this.removeRequestWithId(id);
			}
			return of(error);
		}));
	}
}
