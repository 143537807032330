import {Action} from '@ngrx/store';
import {SubscriptionState} from '../../../generated/graphql';

export enum PaymentActionTypes {
	LoadBrandSubscriptionState = '[Payment] Load Brand Subscription State',
	SetBrandSubscriptionState = '[Payment] Set Brand Subscription State'
}

export class LoadBrandSubscriptionState implements Action {
	readonly type = PaymentActionTypes.LoadBrandSubscriptionState;
}

export class SetBrandSubscriptionState implements Action {
	readonly type = PaymentActionTypes.SetBrandSubscriptionState;

	constructor(public payload: { subscriptionState: SubscriptionState }) {
	}
}


export type PaymentActions =
	| LoadBrandSubscriptionState
	| SetBrandSubscriptionState;
