import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {LeftDirective} from '@caroo/shared/forms/layout/left.directive';
import {FormFieldComponent} from './form-field';
import {InputDirective, InputLabelDirective} from './input';

@NgModule({
	declarations: [
		FormFieldComponent,
		InputDirective,
		InputLabelDirective,
		LeftDirective
	],
	imports: [
		CommonModule,
		MatIconModule
	],
	exports: [
		FormFieldComponent,
		InputDirective,
		InputLabelDirective,
		LeftDirective
	]
})
export class CarooFormsModule {
}
