import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {LocalStorage} from '@caroo/storage/decorator';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class InvalidTokenInterceptor implements HttpInterceptor {
	@LocalStorage
	private token: string;

	constructor(
		private readonly angularFireAuth: AngularFireAuth,
		private router: Router
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next
			.handle(req)
			.pipe(catchError(error => of(error)))
			.pipe(map((response: any) => {
				if (response instanceof HttpErrorResponse && (req.url.includes('caroo') || new URL(req.url).port === '3000')) {
					if ((response.status === 401 && !req.url.includes('login')) || response.status === 403) {
						this.angularFireAuth.auth.signOut().catch(console.error);
						this.token = undefined;
						this.router.navigateByUrl('/login').catch(console.error);
						return response;
					} else {
						throw response;
					}
				} else {
					return response;
				}
			}));
	}
}
